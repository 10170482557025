import { useFormContext } from "react-hook-form";
import { get } from "lodash";

interface Props {
  name: string;
}

const Error = (props: Props) => {
  const { formState } = useFormContext();
  const errorMsg = get(formState.errors, `${props.name}.message`);

  return errorMsg && <div className='text-xs text-red-800 font-medium mt-1'>{errorMsg}</div>;
};

export default Error;
