import { Device } from "classes/Device";
import Button from "components/Forms/Button";
import { IconIv2goOutline } from "components/SVG/Icons";
import { AlarmType } from "enums";
import { DeviceInterface } from "interfaces/DeviceInterface";
import { toast } from "react-toastify";

export default function DeviceSettingsCard(props: { device: DeviceInterface }) {
  const device = new Device(props.device);

  return (
    <div className="bg-white border-2 border-accent rounded-2xl p-8 space-y-6">
      <div className="flex justify-between items-start">
        <div>
          <div className="text-6xl leading-9 mb-4">{device.getDeviceNumber()}</div>
          <button onClick={() => toast.info("Not available in demo")} className="text-sm underline">
            Edit device number
          </button>
        </div>
        <div>
          <IconIv2goOutline className="text-gray-700 w-12 h-20" />
        </div>
      </div>
      <div className="flex justify-between items-center text-xl">
        <div className="text-xl font-medium">Status</div>
        <div className="text-base font-medium">
          {device.hasAlarm(AlarmType.NoSignal) ? (
            <div className="text-warning">Device not connected</div>
          ) : device.hasActiveTreatment() ? (
            <div className="text-accent-darker">Active treatment</div>
          ) : (
            <div className="text-gray-500">No active treatment</div>
          )}
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex justify-between items-center text-gray-500">
          <div>Serial no.</div>
          <div>{device.getDeviceId()}</div>
        </div>
        <div className="flex justify-between items-center text-gray-500">
          <div>Firmware</div>
          <div>V.01.05</div>
        </div>
        <div className="text-right text-sm">
          <button type="button" onClick={() => toast.info("Not available in demo")} className="underline text-gray-900">
            Firmware update available
          </button>
        </div>
      </div>
      <div className="flex flex-col items-center space-y-4">
        <div className="flex justify-center">
          <Button
            type="button"
            appearance="secondary"
            label="Download datalog"
            onClick={() => toast.info("Not available in demo")}
          />
        </div>
        <div className="text-center">
          <Button
            type="button"
            appearance="secondary"
            label="Test device alarms"
            onClick={() => toast.info("Not available in demo")}
          />
          <div className="text-xs text-gray-500 mt-2">
            This will activate device display alarm
            <br />
            and device audio alarm.
          </div>
        </div>
      </div>
    </div>
  );
}
