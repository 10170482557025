import { styled, Switch } from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import FormElementInterface from "interfaces/FormElementInterface";
import FormError from "../Error";
import Label from "../Label";

interface Props extends FormElementInterface {
  checked: boolean;
  className?: string;
  disabled?: boolean;
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 20,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(24px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#3DA185",
      },
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.5,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.20)",
    boxSizing: "border-box",
  },
}));

const Toggle = (props: Props) => {
  const [checked, setChecked] = useState(props.checked);
  const ctx = useFormContext();

  return (
    <Controller
      name={props.name}
      control={ctx.control}
      render={({ field: { onChange, onBlur } }) => {
        const onValueChanged = (e: any) => {
          setChecked(e.target.checked);
          onChange(e.target.checked);
        };

        return (
          <div className={props.className}>
            <Label name={props.name} label={props.label} />
            <AntSwitch checked={checked} onBlur={onBlur} onChange={onValueChanged} disabled={props.disabled} />
            <FormError name={props.name} />
          </div>
        );
      }}
    />
  );
};

export default Toggle;
