import { routes } from "components/Routes";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { authStore } from "stores/AuthStore";

const LoggedInContainer = observer(() => {
  const navigate = useNavigate();
  const isLoggedIn = authStore.isLoggedIn;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(routes.login);
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) return null;

  return (
    <div>
      <Outlet />
    </div>
  );
});

export default LoggedInContainer;
