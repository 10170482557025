import {
  CheckIcon,
  InformationCircleIcon,
  PencilIcon,
  SignalIcon,
  SignalSlashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Device } from "classes/Device";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import Select from "components/Forms/Select";
import Textarea from "components/Forms/Textarea";
import Toggle from "components/Forms/Toggle";
import { IconBattery, IconBatteryCharging } from "components/SVG/Icons";
import {
  PictogramComplete,
  PictogramFlowChange,
  PictogramFlowStop,
  PictogramIdle,
  PictogramLowBattery,
  PictogramNew,
  PictogramNoSignal,
  PictogramRunning,
  PictogramTilt,
} from "components/SVG/Pictograms";
import Tooltip from "components/Tooltip";
import { AlarmType, TreatmentType } from "enums";
import { classNames } from "helpers/classNames";
import { toTitleCase } from "helpers/toTitleCase";
import { BagsizeInterface } from "interfaces/BagsizeInterface";
import { DeviceInterface } from "interfaces/DeviceInterface";
import { UpdateTreatmentInterface } from "interfaces/TreatmentInterface";
import { YupInterface } from "interfaces/YupInterface";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { bagsizeStore } from "stores/BagsizeStore";
import { deviceStore } from "stores/DeviceStore";
import { treatmentStore } from "stores/TreatmentStore";

interface Props {
  device: DeviceInterface;
  onCollapse: Function;
}

const DeviceCardDetails = observer((props: Props) => {
  const selectedDevice = deviceStore.device?.find((d) => d.id === props.device.id);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key !== "Escape") {
        return;
      }

      event.preventDefault();

      if (editMode) {
        setEditMode(false);
      } else {
        props.onCollapse();
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => window.removeEventListener("keydown", handleEsc);
  }, [editMode]);

  const validation = (yup: YupInterface) => {
    return {
      room: yup.string(),
      bedNumber: yup.string(),
      name: yup.string(),
      primaryPhone: yup.string(),
      secondaryPhone: yup.string(),
      notes: yup.string(),
    };
  };

  if (!selectedDevice) {
    return null;
  }

  const bagsizes = bagsizeStore.bagsizes;

  const device = new Device(selectedDevice);

  const { cardBgColor, cardBorderColor } = device.getCardColors();

  const handleSubmit = async (treatment: UpdateTreatmentInterface) => {
    let results = await treatmentStore.updateTreatment(props.device.id, treatment);

    if (results === true) {
      toast("Saved!", { type: "success" });

      setEditMode(false);

      deviceStore.getDevice(props.device.id);
    } else {
      toast("An error occurred!", { type: "error" });
    }
  };

  const bagsizeOptions =
    bagsizes?.map((bagsize: BagsizeInterface) => {
      return {
        id: bagsize.id,
        value: bagsize.name,
      };
    }) || [];

  const typeOptions = [
    {
      id: 0,
      value: toTitleCase(TreatmentType[0]),
    },
    {
      id: 1,
      value: toTitleCase(TreatmentType[1]),
    },
    {
      id: 2,
      value: toTitleCase(TreatmentType[2]),
    },
    {
      id: 3,
      value: toTitleCase(TreatmentType[3]),
    },
  ];

  const alertOptions = [
    {
      id: 0,
      value: "0 min",
    },
    {
      id: 5,
      value: "5 min",
    },
    {
      id: 10,
      value: "10 min",
    },
    {
      id: 15,
      value: "15 min",
    },
  ];

  return (
    <div className="p-6 relative mb-6 pt-16">
      <div className="absolute bottom-7 right-8 text-gray-400 text-xs">ID: {device.getDeviceId()}</div>

      <div
        className="absolute top-0 right-8 cursor-pointer p-2 m-2 rounded-full hover:bg-accent"
        onClick={() => props.onCollapse()}
      >
        <XMarkIcon className="h-7" />
      </div>

      <Form onSubmit={handleSubmit} validation={validation}>
        <div className="flex-grow flex flex-col xl:items-start 2xl:items-stretch lg:flex-row gap-2">
          <div className={classNames("flex-none flex w-full lg:w-[375px] p-0.5 rounded-lg", cardBgColor)}>
            <div className="flex-grow flex flex-col justify-start items-center gap-10 bg-white ml-4 rounded-lg px-5 py-8">
              <div className="w-full flex justify-between gap-4 items-center">
                <div className="flex-none text-8xl leading-none font-medium">{device.getDeviceNumber()}</div>
                <div className="flex-grow">
                  <div className="text-xl font-bold">{device.treatment?.getName() || "-"}</div>
                  <div className="text-gray-500">{device.treatment?.getTypeName() || "-"}</div>
                </div>
              </div>

              <div className="w-full max-w-[375px] space-y-10">
                <div className="flex flex-col items-center gap-6">
                  <div className="inline-block mx-auto">
                    {(device.hasAlarm(AlarmType.NoSignal) && (
                      <PictogramNoSignal acknowledged={device.isAcknowledged()} />
                    )) ||
                      (device.hasAlarm(AlarmType.Flowstop) && (
                        <PictogramFlowStop acknowledged={device.isAcknowledged()} />
                      )) ||
                      (device.hasAlarm(AlarmType.Flowchange) && (
                        <PictogramFlowChange acknowledged={device.isAcknowledged()} />
                      )) ||
                      (device.hasAlarm(AlarmType.Tilt) && <PictogramTilt acknowledged={device.isAcknowledged()} />) ||
                      (device.getBatteryLevel() <= 10 && <PictogramLowBattery />) ||
                      (device.isComplete() && <PictogramComplete />) ||
                      (device.isNew() && <PictogramNew />) ||
                      (device.isIdle() && <PictogramIdle />) ||
                      (device.isRunning() && <PictogramRunning />)}
                  </div>
                  <div className="text-center leading-relaxed">
                    {(device.hasAlarm(AlarmType.NoSignal) && (
                      <>
                        <div className="text-xl">Signal lost</div>
                        <div className="text-gray-500">Unable to connect to device</div>
                      </>
                    )) ||
                      (device.hasAlarm(AlarmType.Flowstop) && (
                        <>
                          <div className="text-xl">Flow stop</div>
                          <div className="text-gray-500">Check flow on device</div>
                        </>
                      )) ||
                      (device.hasAlarm(AlarmType.Flowchange) && (
                        <>
                          <div className="text-xl">Change in flow</div>
                          <div className="text-gray-500">Check flow on device</div>
                        </>
                      )) ||
                      (device.hasAlarm(AlarmType.Tilt) && (
                        <>
                          <div className="text-xl">Tilted device</div>
                          <div className="text-gray-500">Check device orientation</div>
                        </>
                      )) ||
                      (device.getBatteryLevel() <= 10 && (
                        <>
                          <div className="text-xl">Low battery</div>
                          <div className="text-gray-500">Connect the device to charger</div>
                        </>
                      )) ||
                      (device.isComplete() && (
                        <>
                          <div className="text-xl">Treatment complete</div>
                          <div className="text-gray-500">Device can be turned off</div>
                        </>
                      )) ||
                      (device.isNew() && (
                        <>
                          <div className="text-xl">Treatment started</div>
                          <div className="text-gray-500">Please enter patient information</div>
                        </>
                      )) ||
                      (device.isIdle() && (
                        <>
                          <div className="text-xl">No active treatment</div>
                          <div className="text-gray-500">-</div>
                        </>
                      )) ||
                      (device.isRunning() && (
                        <>
                          <div className="text-xl">Treatment active</div>
                          <div className="text-gray-500">-</div>
                        </>
                      ))}
                  </div>
                </div>

                <div className="w-full flex justify-between gap-4 px-4">
                  <div className="text-center">
                    <div className="text-gray-500">Finish</div>
                    <div className="text-5xl text-gray-900">{device.getFinishTime()?.format("HH:mm") || "--:--"}</div>
                    <div className="text-gray-500 pt-2">
                      Target {device.getTargetFinishTime()?.format("HH:mm") || "--:--"}
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-gray-500">Flowrate</div>
                    <div className="text-5xl text-gray-900">
                      {device.getFlowrate() || "--"}
                      <span className="text-base text-gray-500 ml-2">ml/h</span>
                    </div>
                    <div className="text-gray-500 pt-2">Target {device.getTargetFlowrate() || "--"} ml/h</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-grow flex flex-col gap-2">
            <div
              className={classNames(
                "flex justify-between items-center gap-6 bg-white border-2 rounded-lg py-1 pl-6 pr-3",
                cardBorderColor,
              )}
            >
              <div className="flex items-center">
                {device.hasAlarm(AlarmType.NoSignal) ? (
                  <SignalSlashIcon className="h-6 mr-4" />
                ) : (
                  <SignalIcon className="h-6 mr-4" />
                )}
                <div
                  className={classNames(
                    "flex items-center",
                    (device.hasAlarm(AlarmType.NoSignal) || !device.hasBatteryLevel()) && "opacity-20",
                  )}
                >
                  {device.isCharging() ? (
                    <IconBatteryCharging className="h-6 mr-2" />
                  ) : (
                    <IconBattery level={device.getBatteryLevel()} className="h-4 mr-2" />
                  )}
                  <div className="text-gray-500 text-base">{device.getBatteryStatus()}</div>
                </div>
              </div>
              <div className="flex items-center gap-3">
                {editMode ? (
                  <>
                    <button
                      type="button"
                      className="flex items-center text-sm gap-2 bg-gray-100 hover:bg-gray-200 rounded-full px-5 py-2"
                      onClick={() => setEditMode(false)}
                    >
                      Cancel
                      <XMarkIcon className="h-4" />
                    </button>
                    <button
                      type="submit"
                      className="flex items-center text-sm gap-2 bg-accent hover:bg-accent-dark rounded-full px-5 py-2"
                    >
                      Save
                      <CheckIcon className="h-4" />
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    className={classNames(
                      "flex items-center text-sm gap-2 rounded-full px-5 py-2",
                      device.hasActiveTreatment() ? "bg-gray-100 hover:bg-gray-200" : "opacity-25",
                    )}
                    onClick={() => setEditMode(true)}
                    disabled={!device.hasActiveTreatment()}
                  >
                    Edit
                    <PencilIcon className="h-4" />
                  </button>
                )}
              </div>
            </div>

            <div
              className={classNames(
                "flex flex-col 2xl:flex-row flex-grow p-6 bg-white border-2 rounded-lg",
                cardBorderColor,
              )}
            >
              <div className="w-full 2xl:w-1/3 flex flex-col justify-between">
                <div>
                  <div className="flex justify-between items-center">
                    <div className="text-xl font-medium mt-3 mb-5">Notifications</div>
                    <div className="flex items-center gap-2">
                      <button
                        type="button"
                        className="text-sm underline"
                        onClick={() => toast.info("Not available in demo")}
                      >
                        See all
                      </button>
                      <span className="inline-flex items-center justify-center rounded-full bg-warning text-white text-xs font-bold w-5 h-5">
                        1
                      </span>
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="flex justify-between items-center text-gray-500">
                      <div>SMS</div>
                      <div title={device.treatment?.getStartTime()?.format()}>
                        {device.treatment?.getStartTime()?.format("HH:mm")}
                      </div>
                    </div>
                    <div className="flex justify-between items-center text-gray-500">
                      <div>Test sms sent to patient</div>
                      <div title={device.treatment?.getStartTime()?.format()}>
                        {device.treatment?.getStartTime()?.format("HH:mm")}
                      </div>
                    </div>
                    <div className="flex justify-between items-center text-gray-500">
                      <div>Treatment started</div>
                      <div title={device.treatment?.getStartTime()?.format()}>
                        {device.treatment?.getStartTime()?.format("HH:mm")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-xl font-medium mt-8 mb-5">Treatment information</div>
                <div className="flex justify-between items-center h-9">
                  <div className="whitespace-nowrap flex items-center text-gray-500">Dropbag size:</div>
                  <div>
                    {editMode ? (
                      <Select
                        options={bagsizeOptions}
                        value={device.treatment?.getBagsizeId()}
                        name="bagsizeId"
                        className="m-0"
                      />
                    ) : (
                      <span className="text-gray-900">{device.treatment?.getBagsizeName() || "n/a"}</span>
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center h-9">
                  <div className="whitespace-nowrap flex items-center text-gray-500">Tube set:</div>
                  <div className="text-gray-900 flex justify-end items-center">20 drops/ml</div>
                </div>
                <div className="flex justify-between items-center h-9">
                  <div className="whitespace-nowrap flex items-center text-gray-500">Target duration:</div>
                  <div className="text-gray-900 flex justify-end items-center">
                    {device.getTargetDuration()?.format("HH:mm") || "--:--"}
                  </div>
                </div>
                <div className="flex justify-between items-center h-9">
                  <div className="whitespace-nowrap flex items-center text-gray-500">Estimated duration:</div>
                  <div className="text-gray-900 flex justify-end items-center">
                    {device.getEstimatedDuration()?.format("HH:mm") || "--:--"}
                  </div>
                </div>
                <div className="flex justify-between items-center h-9">
                  <div className="whitespace-nowrap flex items-center text-gray-500">Target flow rate:</div>
                  <div className="text-gray-900 flex justify-end items-center">
                    {device.treatment?.getTargetFlowrate() || "--"} ml/h
                  </div>
                </div>
              </div>
              <div className="w-full 2xl:w-1 border-b-2 2xl:border-b-0 2xl:border-r-2 border-gray-200 my-6 2xl:my-0 2xl:mx-8" />
              <div className="w-full 2xl:w-1/3">
                <div className="text-xl font-medium mt-3 mb-5">Patient information</div>

                <div className="flex justify-between items-start gap-2 min-h-[2.25rem]">
                  <div className="flex items-center text-gray-500">Treatment type:</div>
                  <div className="flex justify-end items-center">
                    {editMode ? (
                      <Select
                        options={typeOptions}
                        value={device.treatment?.getType()}
                        name="type"
                        className="m-0 w-full"
                        hideErrors
                      />
                    ) : (
                      <div className="text-gray-900 text-right">{device.treatment?.getTypeName()}</div>
                    )}
                  </div>
                </div>

                <div className="flex justify-between items-start gap-2 min-h-[2.25rem]">
                  <div className="flex items-center text-gray-500">Location:</div>
                  <div className="flex justify-end items-center">
                    {editMode ? (
                      <div className="grid grid-cols-2 gap-3">
                        <Input
                          type="text"
                          name="room"
                          defaultValue={device.treatment?.getRoom()}
                          placeholder="Room"
                          className="m-0"
                          inputClassName="max-w-[6rem]"
                          hideErrors
                        />
                        <Input
                          type="text"
                          name="bedNumber"
                          defaultValue={device.treatment?.getBedNumber()}
                          placeholder="Bed no."
                          className="m-0"
                          inputClassName="max-w-[6rem]"
                          hideErrors
                        />
                      </div>
                    ) : (
                      <div className="text-gray-900 text-right">
                        {device.treatment?.getRoom() ? `Room ${device.treatment?.getRoom()}` : null}
                        {device.treatment?.getRoom() && device.treatment?.getBedNumber() && " - "}
                        {device.treatment?.getBedNumber() ? `Bed ${device.treatment?.getBedNumber()}` : null}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex justify-between items-start gap-2 min-h-[2.25rem]">
                  <div className="flex items-center text-gray-500">Name:</div>
                  <div className="flex justify-end items-center">
                    {editMode ? (
                      <Input
                        type="text"
                        name="name"
                        defaultValue={device.treatment?.getName()}
                        className="m-0"
                        hideErrors
                      />
                    ) : (
                      <div className="text-gray-900 text-right">{device.treatment?.getName()}</div>
                    )}
                  </div>
                </div>

                <div className="flex justify-between items-start gap-2 min-h-[2.25rem]">
                  <div className="flex items-center text-gray-500 gap-2">
                    Primary phone:
                    <Tooltip text="Both phone numbers will receive notifications">
                      <InformationCircleIcon className="h-5 text-info" />
                    </Tooltip>
                  </div>
                  <div className="flex justify-end items-center">
                    {editMode ? (
                      <Input
                        type="text"
                        name="primaryPhone"
                        defaultValue={device.treatment?.getPrimaryPhone()}
                        className="m-0"
                        hideErrors
                      />
                    ) : (
                      <div className="text-gray-900 text-right">{device.treatment?.getPrimaryPhone()}</div>
                    )}
                  </div>
                </div>

                <div className="flex justify-between items-start gap-2 min-h-[2.25rem]">
                  <div className="flex items-center text-gray-500">Secondary phone:</div>
                  <div className="flex justify-end items-center">
                    {editMode ? (
                      <Input
                        type="text"
                        name="secondaryPhone"
                        defaultValue={device.treatment?.getSecondaryPhone()}
                        className="m-0"
                        hideErrors
                      />
                    ) : (
                      <div className="text-gray-900 text-right">{device.treatment?.getSecondaryPhone()}</div>
                    )}
                  </div>
                </div>

                <div className="text-xl font-medium mt-3 mb-3">Notes</div>
                <div className="2xl:h-[100px]">
                  {editMode ? (
                    <Textarea
                      name="notes"
                      className="m-0"
                      defaultValue={device.treatment?.getNotes()}
                      minRows={4}
                      maxRows={4}
                      hideErrors
                    />
                  ) : (
                    <div className="text-gray-900 pt-2">{device.treatment?.getNotes()}</div>
                  )}
                </div>
              </div>
              <div className="w-full 2xl:w-1 border-b-2 2xl:border-b-0 2xl:border-r-2 border-gray-200 my-6 2xl:my-0 2xl:mx-8" />
              <div className="w-full 2xl:w-1/3">
                <div className="text-xl font-medium mt-3 mb-5">Device setup</div>

                <div className="flex justify-between items-start gap-2">
                  <div className="flex items-center text-gray-500 min-h-[2.25rem]">Alert before end of treatment:</div>
                  <div className="flex justify-end items-center h-9">
                    {editMode ? (
                      <Select options={alertOptions} value={0} name="alertBefore" className="m-0" />
                    ) : (
                      <div className="text-gray-900 text-right">
                        {device.treatment?.getAlertBefore() !== undefined
                          ? `${Number(device.treatment?.getAlertBefore())} min`
                          : "-"}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex justify-between items-start gap-2">
                  <div className="flex items-center text-gray-500 min-h-[2.25rem]">
                    Flash alarm on device:
                    <Tooltip text="Flash alarm">
                      <InformationCircleIcon className="h-5 text-info" />
                    </Tooltip>
                  </div>
                  <div className="flex justify-end items-center h-9">
                    <Toggle name="alarmFlash" checked={!!device.treatment?.getAlarmFlash()} disabled={!editMode} />
                  </div>
                </div>

                <div className="flex justify-between items-start gap-2">
                  <div className="flex items-center text-gray-500 min-h-[2.25rem]">
                    Sound alarm on device:
                    <Tooltip text="Sound alarm">
                      <InformationCircleIcon className="h-5 text-info" />
                    </Tooltip>
                  </div>
                  <div className="flex justify-end items-center h-9">
                    <Toggle name="alarmSound" checked={!!device.treatment?.getAlarmSound()} disabled={!editMode} />
                  </div>
                </div>

                <div className="flex justify-between items-start gap-2">
                  <div className="flex items-center text-gray-500 min-h-[2.25rem]">Enable patient notifications (SMS):</div>
                  <div className="flex justify-end items-center h-9">
                    <Toggle name="smsEnabled" checked={!!device.treatment?.getSmsEnabled()} disabled={!editMode} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
});

export default DeviceCardDetails;
