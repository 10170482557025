type Value = string | number | boolean | undefined | null;
type Mapping = Record<string, unknown>;
interface ArgumentArray extends Array<Argument> {}
type Argument = Value | Mapping | ArgumentArray;

export function classNames(...args: ArgumentArray): string {
  var classes = [];
  var hasOwn = {}.hasOwnProperty;

  for (var i = 0; i < arguments.length; i++) {
    var arg = arguments[i];
    if (!arg) continue;

    var argType = typeof arg;

    if (argType === "string" || argType === "number") {
      classes.push(arg);
    } else if (Array.isArray(arg)) {
      if (arg.length) {
        var inner = classNames.apply(null, arg);
        if (inner) {
          classes.push(inner);
        }
      }
    } else if (argType === "object") {
      if (arg.toString !== Object.prototype.toString && !arg.toString.toString().includes("[native code]")) {
        classes.push(arg.toString());
        continue;
      }

      for (var key in arg) {
        if (hasOwn.call(arg, key) && arg[key]) {
          classes.push(key);
        }
      }
    }
  }

  return classes.join(" ");
}
