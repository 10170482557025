import React, { PropsWithChildren } from "react";
import classNames from "classnames";

interface Props {
  text: string;
  className?: string;
}

export default function Tooltip(props: PropsWithChildren<Props>) {
  return (
    <div className="group relative">
      <div className={classNames("tooltip", props.className)}>{props.text}</div>
      {props.children}
    </div>
  );
}
