import FormElementInterface from "interfaces/FormElementInterface";
import FormError from "components/Forms/Error";
import Label from "components/Forms/Label";
import { useFormContext } from "react-hook-form";
import css from "./style.module.scss";
import { useEffect } from "react";
import { classNames } from "helpers/classNames";

export interface OptionInterface {
  id: string | number;
  value: string;
}

interface Props extends FormElementInterface {
  options: OptionInterface[];
  value?: string | number;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  hideErrors?: boolean;
}

const Select = (props: Props) => {
  const { register, unregister, formState } = useFormContext();

  useEffect(() => {
    return () => {
      unregister(props.name);
    };
  }, []);

  return (
    <div className={classNames(props.className || "w-full", props.disabled && "cursor-not-allowed")}>
      <Label name={props.name} label={props.label} />
      <div className="relative">
        <div className="absolute z-10 top-0 right-1 px-1 w-7 h-full flex justify-center items-center">
          <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.50098 0.762939L4.75098 4.51294L1.00098 0.762939" stroke="#231F20" strokeMiterlimit="10" />
          </svg>
        </div>
        <select
          className={classNames(
            "appearance-none bg-transparent text-gray-900 pl-3 pr-8 py-1 rounded-2xl relative z-20",
            "border border-gray-300 w-full max-w-full",
            formState.errors?.[props.name] && "border border-red-400",
            props.inputClassName,
            props.disabled && css.disabled,
          )}
          {...register(props.name)}
          defaultValue={props.value}
        >
          {props.placeholder && <option value="">{props.placeholder}</option>}

          {props.options.map((opt, index) => (
            <option key={opt.id} value={opt.id}>
              {opt.value}
            </option>
          ))}
        </select>
      </div>
      {!props.hideErrors && <FormError name={props.name} />}
    </div>
  );
};

export default Select;
