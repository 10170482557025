import { useFormContext } from "react-hook-form";
import FormElementInterface from "interfaces/FormElementInterface";
import FormError from "components/Forms/Error";
import Label from "components/Forms/Label";
import { useEffect } from "react";
import { classNames } from "helpers/classNames";

interface Props extends FormElementInterface {
  type?: "text" | "password" | "checkbox" | "email";
  defaultChecked?: boolean;
  className?: string;
  inputClassName?: string;
  hideErrors?: boolean;
}

const Input = (props: Props) => {
  const { register, unregister, formState } = useFormContext();

  useEffect(() => {
    return () => {
      unregister(props.name);
    };
  }, []);

  return (
    <div className={props.className}>
      <Label name={props.name} label={props.label} />
      <input
        className={classNames(
          "border border-gray-300 rounded-2xl w-full px-3 py-1 text-gray-900",
          props.inputClassName,
          formState.errors?.[props.name] && "border border-red-400",
        )}
        {...register(props.name)}
        type={props.type || "text"}
        defaultChecked={props.defaultChecked}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
      />
      {!props.hideErrors && <FormError name={props.name} />}
    </div>
  );
};

export default Input;
