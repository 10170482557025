interface Props {
  name?: string;
  label?: string | React.ReactNode;
}

const Label = (props: Props) => {
  if (!props.label) return null;

  return (
    <label className="block mb-1" htmlFor={props.name}>
      {props.label}
    </label>
  );
};

export default Label;
