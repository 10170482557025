import { Device } from "classes/Device";
import { IconBattery, IconBatteryCharging } from "components/SVG/Icons";
import { AlarmType } from "enums";
import { classNames } from "helpers/classNames";
import { DeviceInterface } from "interfaces/DeviceInterface";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { deviceStore } from "stores/DeviceStore";
import DeviceCardIcon from "./DeviceCardIcon";

interface Props {
  device: DeviceInterface;
  onExpand: Function;
  onCollapse: Function;
}

const DeviceCard = observer((props: Props) => {
  const device = deviceStore.device?.find((d) => d.id === props.device.id);

  useEffect(() => {
    const interval = setInterval(() => {
      deviceStore.getDevice(props.device.id);
    }, 10000);

    deviceStore.getDevice(props.device.id);

    return () => clearInterval(interval);
  }, []);

  if (!device) {
    return null;
  }

  const deviceObject = new Device(device);

  const { cardBgColor, cardIconColor } = deviceObject.getCardColors();

  return (
    <div className={classNames("flex p-0.5 rounded-lg cursor-pointer relative overflow-hidden", cardBgColor)} onClick={() => props.onExpand()}>
      {deviceObject.treatment?.getRoom() === "999" && <div className="text-xxs bg-purple-400 text-white font-bold absolute top-3 -right-7 py-0.5 px-6 transform rotate-45">simulator</div>}
      <div className="flex-grow px-6 pt-5 pb-5 ml-4 bg-white rounded-lg">
        <div className="w-full flex justify-between gap-2 items-center pb-4 mb-3 border-b border-gray-200 text-gray-700">
          <div className="flex-none text-5xl leading-10 font-medium">{deviceObject.getDeviceNumber()}</div>
          <div className="flex-grow leading-normal">
            <div className="font-medium">{deviceObject.treatment?.getName() || "-"}</div>
            <div className="text-xs text-gray-500">{deviceObject.treatment?.getTypeName() || "-"}</div>
          </div>
          <div className="cursor-pointer" onClick={() => props.onExpand()}>
            <DeviceCardIcon device={deviceObject} className={cardIconColor} />
          </div>
        </div>

        <div className="flex justify-between">
          <div className="flex justify-between gap-8">
            <div className="text-left space-y-2">
              <div className="text-xs text-gray-500">Finish</div>
              <div className={classNames("text-2xl", deviceObject.hasAlarm(AlarmType.NoSignal) && "opacity-20")}>
                {deviceObject.getFinishTime()?.format("HH:mm") || "--:--"}
              </div>
            </div>
            <div className="text-left space-y-2">
              <div
                className={classNames(
                  "text-xs",
                  deviceObject.hasAlarm(AlarmType.Flowchange) || deviceObject.hasAlarm(AlarmType.Flowstop)
                    ? "text-warning"
                    : "text-gray-500",
                )}
              >
                Flowrate
              </div>
              <div
                className={classNames(
                  "text-2xl",
                  deviceObject.hasAlarm(AlarmType.Flowchange) || deviceObject.hasAlarm(AlarmType.Flowstop)
                    ? "text-warning"
                    : "text-gray-900",
                  deviceObject.hasAlarm(AlarmType.NoSignal) && "opacity-20",
                )}
              >
                {deviceObject.getFlowrate() || "--"}
                <span className="text-sm ml-1">ml/h</span>
              </div>
            </div>
          </div>
          <div className="text-left space-y-2">
            <div className="text-xs">&nbsp;</div>
            <div
              className={classNames(
                "text-2xl",
                (deviceObject.hasAlarm(AlarmType.NoSignal) || !deviceObject.hasBatteryLevel()) && "opacity-20",
              )}
              title={deviceObject.getBatteryStatus()}
            >
              {deviceObject.isCharging() ? (
                <IconBatteryCharging className="mt-1" />
              ) : (
                <IconBattery level={deviceObject.getBatteryLevel()} className="mt-1" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default DeviceCard;
