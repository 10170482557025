import { TreatmentType } from "enums";
import { toTitleCase } from "helpers/toTitleCase";
import { TreatmentInterface } from "interfaces/TreatmentInterface";
import moment from "moment";

export class Treatment {
  private treatment: TreatmentInterface;

  constructor(treatment: TreatmentInterface) {
    this.treatment = treatment;
  }

  public getTreatment(): TreatmentInterface {
    return this.treatment;
  }

  public getId(): string {
    return this.treatment.id;
  }

  public getType(): TreatmentType | undefined {
    return this.treatment.type;
  }

  public getTypeName(): string | undefined {
    return this.treatment.type ? toTitleCase(TreatmentType[this.treatment.type]) : undefined;
  }

  public getStartTime(): moment.Moment | null {
    return this.treatment.startTime ? moment(this.treatment.startTime) : null;
  }

  public getEndTime(): moment.Moment | null {
    return this.treatment.endTime ? moment(this.treatment.endTime) : null;
  }

  public getTubeset(): string | undefined {
    return this.treatment.tubeset;
  }

  public getTargetDuration(): number | undefined {
    return this.treatment.targetDuration;
  }

  public getTargetFlowrate(): number | undefined {
    return this.treatment.targetFlowrate;
  }

  public getName(): string | undefined {
    return this.treatment.name;
  }

  public getRoom(): string | undefined {
    return this.treatment.room;
  }

  public getBedNumber(): string | undefined {
    return this.treatment.bedNumber;
  }

  public getPrimaryPhone(): string | undefined {
    return this.treatment.primaryPhone;
  }

  public getSecondaryPhone(): string | undefined {
    return this.treatment.secondaryPhone;
  }

  public getNotes(): string | undefined {
    return this.treatment.notes;
  }

  public getAlertBefore(): number | undefined {
    return this.treatment.alertBefore;
  }

  public getAlarmFlash(): boolean {
    return this.treatment.alarmFlash;
  }

  public getAlarmSound(): boolean {
    return this.treatment.alarmSound;
  }

  public getSmsEnabled(): boolean {
    return this.treatment.smsEnabled;
  }

  public getBagsizeId(): string | undefined {
    return this.treatment.bagsize?.id;
  }

  public getBagsizeName(): string | undefined {
    return this.treatment.bagsize?.name || "N/A";
  }
}
