import DeviceSettingsCard from "components/Cards/DeviceSettingsCard";
import Form from "components/Forms/Form";
import Input from "components/Forms/Input";
import Select from "components/Forms/Select";
import Toggle from "components/Forms/Toggle";
import { YupInterface } from "interfaces/YupInterface";
import { deviceStore } from "stores/DeviceStore";
import { observer } from "mobx-react-lite";
import { DeviceInterface } from "interfaces/DeviceInterface";
import Tooltip from "components/Tooltip";
import { toast } from "react-toastify";
import { CheckIcon, InformationCircleIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const Settings = observer(() => {
  const devices = deviceStore.devices;

  const handleSubmit = () => {
    toast.info("Not available in demo");
  };

  const validation = (yup: YupInterface) => {
    return {
      title: yup.string().required("Titel skal udfyldes"),
    };
  };

  const tubesetOptions = [
    {
      id: 0,
      value: "Tubeset",
    },
    {
      id: 20,
      value: "20 drops/ml",
    },
    {
      id: 30,
      value: "30 drops/ml",
    },
    {
      id: 40,
      value: "40 drops/ml",
    },
    {
      id: 50,
      value: "50 drops/ml",
    },
    {
      id: 60,
      value: "60 drops/ml",
    },
  ];

  const flowstopAlertOptions = [
    {
      id: 0,
      value: "Immediately",
    },
    {
      id: 15,
      value: "15 minutes",
    },
  ];

  const flowchangeAlertOptions = [
    {
      id: 0,
      value: "Immediately",
    },
    {
      id: 15,
      value: "15 minutes",
    },
  ];

  const flowchangeUpperThresholdOptions = [
    {
      id: 0,
      value: "0%",
    },
    {
      id: 5,
      value: "5%",
    },
    {
      id: 10,
      value: "10%",
    },
    {
      id: 15,
      value: "15%",
    },
  ];

  const flowchangeLowerThresholdOptions = [
    {
      id: 0,
      value: "0%",
    },
    {
      id: 5,
      value: "5%",
    },
    {
      id: 10,
      value: "10%",
    },
    {
      id: 15,
      value: "15%",
    },
  ];

  const acknowledgeAlertOptions = [
    {
      id: 0,
      value: "Immediately",
    },
    {
      id: 15,
      value: "15 minutes",
    },
  ];

  return (
    <div className="space-y-8">
      <div className="bg-white border-2 border-accent rounded-2xl px-12 py-8 flex flex-col">
        <Form onSubmit={handleSubmit} validation={validation}>
          <div className="flex justify-between items-center gap-6 border-b border-gray-200 pb-6 mb-6">
            <div className="text-2xl font-medium">Settings</div>
            <div>
              <button
                type="submit"
                className="flex items-center text-sm gap-2 bg-accent hover:bg-accent-dark rounded-full px-5 py-2"
                onClick={() => toast.info("Not available in demo")}
              >
                Save
                <CheckIcon className="h-4" />
              </button>
            </div>
          </div>
          <div className="flex flex-col xl:flex-row xl:justify-between">
            <div>
              <div className="w-full space-y-2 pb-4">
                <div className="w-full">
                  <button
                    type="button"
                    className="w-full text-left whitespace-nowrap hover:bg-accent/50 bg-accent/50 rounded-2xl px-6 py-4"
                  >
                    Devices
                  </button>
                </div>
                <div className="w-full">
                  <button
                    type="button"
                    onClick={() => toast.info("Not available in demo")}
                    className="w-full text-left whitespace-nowrap hover:bg-accent/50 active:bg-accent/50 rounded-2xl px-6 py-4"
                  >
                    Locations
                  </button>
                </div>
                <div className="w-full">
                  <button
                    type="button"
                    onClick={() => toast.info("Not available in demo")}
                    className="w-full text-left whitespace-nowrap hover:bg-accent/50 active:bg-accent/50 rounded-2xl px-6 py-4"
                  >
                    SMS notifications
                  </button>
                </div>
                <div className="w-full">
                  <button
                    type="button"
                    onClick={() => toast.info("Not available in demo")}
                    className="w-full text-left whitespace-nowrap hover:bg-accent/50 active:bg-accent/50 rounded-2xl px-6 py-4"
                  >
                    Auto messages
                  </button>
                </div>
                <div className="w-full">
                  <button
                    type="button"
                    onClick={() => toast.info("Not available in demo")}
                    className="w-full text-left whitespace-nowrap hover:bg-accent/50 active:bg-accent/50 rounded-2xl px-6 py-4"
                  >
                    Time and language
                  </button>
                </div>
                <div className="w-full">
                  <button
                    type="button"
                    onClick={() => toast.info("Not available in demo")}
                    className="w-full text-left whitespace-nowrap hover:bg-accent/50 active:bg-accent/50 rounded-2xl px-6 py-4"
                  >
                    Users
                  </button>
                </div>
              </div>
            </div>

            <div className="my-12 border-b xl:my-0 xl:border-b-0 xl:mx-12 xl:border-r border-gray-200" />

            <div className="flex-grow">
              <div className="text-xl font-medium mt-4 mb-5">Device setup (global)</div>
              <div className="space-y-10">
                <div className="grid grid-cols-2 gap-1">
                  <div className="whitespace-nowrap flex items-center text-gray-500 h-12">Change tubeset</div>
                  <div className="flex justify-end items-center h-12">
                    <Select options={tubesetOptions} value={20} name="tubeset" inputClassName="py-2" />
                  </div>
                </div>

                <div className="space-y-2">
                  <div className="text-xl font-medium mt-4 mb-5">iV bag sizes</div>
                  <div>
                    <button
                      onClick={() => toast.info("Not available in demo")}
                      className="text-sm text-gray-500 underline"
                    >
                      Add new bag size
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => toast.info("Not available in demo")}
                      className="text-sm text-gray-500 underline"
                    >
                      Remove existing bag size
                    </button>
                  </div>
                </div>

                <div>
                  <div className="text-xl font-medium mt-4 mb-5">Device alarm defaults</div>
                  <div className="text-gray-500 mb-4 max-w-xs">
                    Enabling device alarms here will cause the device alarm to be on by default on any new treatment.
                  </div>
                  <div className="grid grid-cols-2 gap-1">
                    <div className="whitespace-nowrap flex items-center text-gray-500 h-9">
                      Flash alarm on device:
                      <Tooltip text="Flash alarm">
                        <InformationCircleIcon className="h-5 text-info" />
                      </Tooltip>
                    </div>
                    <div className="flex justify-end items-center h-9">
                      <Toggle name="alarm_flash" checked={true} />
                    </div>

                    <div className="whitespace-nowrap flex items-center text-gray-500 h-9">
                      Sound alarm on device:
                      <Tooltip text="Sound alarm">
                        <InformationCircleIcon className="h-5 text-info" />
                      </Tooltip>
                    </div>
                    <div className="flex justify-end items-center h-9">
                      <Toggle name="alarm_sound" checked={false} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-12 border-b xl:my-0 xl:border-b-0 xl:mx-12 xl:border-r border-gray-200" />

            <div className="flex-grow min-w-fit">
              <div className="text-xl font-medium mt-4 mb-5">Alert timers</div>
              <div className="space-y-6">
                <div>
                  <div className="text-xs font-medium mb-1">Flow stop</div>
                  <div className="grid grid-cols-2 gap-1">
                    <div className="whitespace-nowrap flex items-center text-gray-500 h-12">
                      Alert delay on flow stop
                    </div>
                    <div className="flex justify-end items-center h-12">
                      <Select options={flowstopAlertOptions} value={15} name="alert_before" inputClassName="py-2" />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="text-xs font-medium mb-1">Flow change</div>
                  <div className="grid grid-cols-2 gap-1">
                    <div className="whitespace-nowrap flex items-center text-gray-500 h-12">
                      Alert delay on flow out of target
                    </div>
                    <div className="flex justify-end items-center h-12">
                      <Select
                        options={flowchangeAlertOptions}
                        value={0}
                        name="flow_change_before"
                        inputClassName="py-2"
                      />
                    </div>

                    <div className="whitespace-nowrap flex items-center text-gray-500 h-12">Alert when above target</div>
                    <div className="flex justify-end items-center h-12">
                      <Select
                        options={flowchangeUpperThresholdOptions}
                        value={15}
                        name="flow_change_upper_threshold"
                        inputClassName="py-2"
                      />
                    </div>

                    <div className="whitespace-nowrap flex items-center text-gray-500 h-12">Alert when below target</div>
                    <div className="flex justify-end items-center h-12">
                      <Select
                        options={flowchangeLowerThresholdOptions}
                        value={10}
                        name="flow_change_lower_threshold"
                        inputClassName="py-2"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="text-xs font-medium mb-1">Acknowledge</div>
                  <div className="grid grid-cols-2 gap-1">
                    <div className="whitespace-nowrap flex items-center text-gray-500 h-12">
                      Alert reminder after acknowledge
                    </div>
                    <div className="flex justify-end items-center h-12">
                      <Select
                        options={acknowledgeAlertOptions}
                        value={15}
                        name="acknowledge_alert_after"
                        inputClassName="py-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>

      <div className="space-y-6">
        <div className="flex justify-between items-center gap-6 mb-2">
          <div className="text-2xl font-medium">Device settings</div>
          <div className="flex items-center gap-4 w-80">
            <Form onSubmit={() => toast.info("Not available in demo")}>
              <div className="relative w-full">
                <div className="absolute inset-y-0 right-4 h-full flex items-center">
                  <MagnifyingGlassIcon className="text-gray-500 h-5" />
                </div>
                <Input name="search" placeholder="Search" inputClassName="py-2 w-full" />
              </div>
            </Form>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-10">
          {devices &&
            devices.length > 0 &&
            devices?.map((device: DeviceInterface) => <DeviceSettingsCard key={device.id} device={device} />)}
        </div>
      </div>
    </div>
  );
});

export default Settings;
