import { autorun } from "mobx";

export default abstract class SaveableStore {
  private sessionName: string;
  sessionProperties: string[] = [];

  constructor(sessionName?: string) {
    this.sessionName = sessionName || `${this.constructor.name}Session`;
  }

  private setSession() {
    // use autorun to monitor for property changes and update session storage accordingly
    autorun((v) => {
      let data = JSON.parse("{}");
      for (let key in this) {
        if (this.sessionProperties.indexOf(key) !== -1) {
          data[key] = this[key];
        }
      }
      sessionStorage.setItem(this.sessionName, JSON.stringify(data));
    });
  }

  private setProperty<T, K extends keyof T>(obj: T, key: K, value: any) {
    // set 'any' property/key (K) value of a generic object type (T)
    obj[key] = value;
  }

  initSessionStorage<T>(sessionStore: T, properties: Array<string>) {
    this.sessionProperties = properties;

    // fetch data from session and set in store
    if (sessionStorage.getItem(this.sessionName)) {
      let storeData = sessionStorage.getItem(this.sessionName) as string;
      let data: T = JSON.parse(storeData);
      for (let key in data) {
        if (this.hasOwnProperty(key)) {
          this.setProperty(sessionStore, key, data[key]);
        }
      }
    }

    // monitor and set data (sessionProperties) from store into session
    this.setSession();
  }
}
