import { AlarmState, AlarmType, RunningState, TreatmentType } from "enums";
import { DeviceInterface } from "interfaces/DeviceInterface";
import moment from "moment";
import { Treatment } from "./Treatment";

type DeviceColors = {
  cardBgColor: string;
  cardBorderColor: string;
  cardIconColor: string;
};

export class Device {
  private device: DeviceInterface;
  public treatment: Treatment | undefined;

  constructor(device: DeviceInterface) {
    this.device = device;
    this.treatment = device.latestTreatment ? new Treatment(device.latestTreatment) : undefined;
  }

  public getDevice(): DeviceInterface {
    return this.device;
  }

  public getDeviceId(): string {
    return this.device.uniqueId;
  }

  public getDeviceNumber(): string {
    return this.device.deviceNo ? String(this.device.deviceNo).padStart(2, "0") : "00";
  }

  public hasFlowrate(): boolean {
    return !!this.device.flowrate;
  }

  public getFlowrate(): number | undefined {
    return this.device.flowrate;
  }

  public hasBatteryLevel(): boolean {
    return !!this.device.batteryLevel;
  }

  public getBatteryLevel(): number {
    if (!this.hasBatteryLevel()) {
      return -1;
    }

    return this.device.batteryLevel;
  }

  public getBatteryStatus(): string {
    if (this.device.batteryLevel === null) {
      return "unknown";
    }

    if (this.device.runningState === RunningState.Charging) {
      return "charging";
    }

    return `${this.device.batteryLevel}%`;
  }

  public isIdle(): boolean {
    return (
      this.device.runningState === RunningState.Idle ||
      (this.device.runningState === RunningState.Charging && this.device.alarmState === AlarmState.Inactive)
    );
  }

  public isNew(): boolean {
    return (
      this.device.runningState === RunningState.Active &&
      this.device.alarmState === AlarmState.Inactive &&
      this.device.latestTreatment !== null &&
      this.device.latestTreatment!.name === null
    );
  }

  public isRunning(): boolean {
    return (
      this.device.runningState === RunningState.Active &&
      this.device.alarmState === AlarmState.Inactive &&
      this.device.latestTreatment !== null &&
      this.device.latestTreatment!.name !== null
    );
  }

  public isComplete(): boolean {
    return this.device.runningState === RunningState.Finished;
  }

  public isCharging(): boolean {
    return this.device.runningState === RunningState.Charging;
  }

  public hasAlarm(type: AlarmType): boolean {
    if (this.device.alarmState === AlarmState.Inactive) {
      return false;
    }

    return this.device.alarmType === type;
  }

  public isAcknowledged(): boolean {
    return this.device.alarmState === AlarmState.Acknowledged;
  }

  public hasNoAlarm(): boolean {
    return this.device.alarmState === AlarmState.Inactive || this.device.alarmType === AlarmType.None;
  }

  public hasPatientInformation(): boolean {
    return !!this.device.latestTreatment?.name;
  }

  public hasActiveTreatment(): boolean {
    return this.treatment?.getEndTime() === null;
  }

  public getCardColors(): DeviceColors {
    // Default colors
    let cardBgColor = "bg-gray-300";
    let cardBorderColor = "border-gray-300";
    let cardIconColor = "text-gray-900";

    // Colors based on running state
    if (
      (this.device.runningState === RunningState.Charging || this.device.runningState === RunningState.Idle) &&
      this.device.latestTreatment?.endTime !== null
    ) {
      cardBgColor = "bg-gray-300";
      cardBorderColor = "border-gray-300";
    } else if (
      (this.device.runningState === RunningState.Charging ||
        this.device.runningState === RunningState.Active ||
        this.device.runningState === RunningState.Configuration) &&
      this.device.latestTreatment?.endTime === null
    ) {
      cardBgColor = "bg-accent";
      cardBorderColor = "border-accent";
    } else if (this.device.runningState === RunningState.Finished) {
      cardBgColor = "bg-success";
      cardBorderColor = "border-success";
      cardIconColor = "text-success";
    }

    // Colors based on conditions
    if (this.device.batteryLevel !== null && this.device.batteryLevel <= 10) {
      cardBgColor = "bg-warning";
      cardBorderColor = "border-warning";
      cardIconColor = "text-warning";
    } else if (this.device.runningState === RunningState.Active && !this.device.latestTreatment?.name) {
      cardBgColor = "bg-info";
      cardBorderColor = "border-info";
    }

    // Alarms always have highest priority
    if (this.device.alarmState === AlarmState.Active) {
      cardBgColor = "bg-warning";
      cardBorderColor = "border-warning";
      cardIconColor = "text-warning";
    } else if (this.device.alarmState === AlarmState.Acknowledged) {
      cardBgColor = "bg-alert";
      cardBorderColor = "border-alert";
      cardIconColor = "text-alert";
    }

    return { cardBgColor, cardBorderColor, cardIconColor };
  }

  public getFinishTime(): moment.Moment | null {
    if (!(this.device.latestTreatment?.endTime || this.device.timeLeft)) {
      return null;
    }

    if (this.device.latestTreatment?.endTime) {
      return moment(this.device.latestTreatment?.endTime);
    }

    return moment().add(this.device.timeLeft, "seconds");
  }

  public getTargetFinishTime(): moment.Moment | null {
    if (!this.treatment?.getStartTime()) {
      return null;
    }

    return this.treatment.getStartTime()!.add(this.treatment.getTargetDuration(), "seconds");
  }

  public getTargetFlowrate(): number | null {
    return this.treatment?.getTargetFlowrate() || null;
  }

  public getTargetDuration(): moment.Moment | null {
    return this.treatment?.getTargetDuration()
      ? moment().startOf("day").add(this.treatment.getTargetDuration(), "seconds")
      : null;
  }

  public getEstimatedDuration(): moment.Moment | null {
    if (!(this.treatment && this.device.timeLeft)) {
      return null;
    }

    let diff = moment().add(this.device.timeLeft, "seconds").diff(moment(this.treatment.getStartTime()), "seconds");
    return moment().startOf("day").add(diff, "seconds");
  }
}
