import LoggedInContainer from "components/LoggedInContainer";
import { createBrowserHistory } from "history";
import { unstable_HistoryRouter as HistoryRouter, Route, Routes } from "react-router-dom";
import ToastContainer from "components/ToastContainer";
import PageOverview from "pages/Overview";
import PageLocations from "pages/Locations";
import PageLogin from "pages/Login";
import PageHome from "pages/Home";
import PageStatistics from "pages/Statistics";
import PageSettings from "pages/Settings";
import PageSupport from "pages/Support";
import AppLayout from "components/Layouts/AppLayout";

export const history = createBrowserHistory();

export const routes = {
  overview: "/",
  locations: "/locations",
  statistics: "/statistics",
  settings: "/settings",
  support: "/support",
  login: "/login",
  home: "/home",
};

const MyRoutes = () => (
  <div>
    <ToastContainer position="top-center" hideProgressBar={true} autoClose={2000} />
    <HistoryRouter history={history}>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path={routes.overview} element={<PageOverview />} />
          <Route path={routes.locations} element={<PageLocations />} />
          <Route path={routes.statistics} element={<PageStatistics />} />
          <Route path={routes.settings} element={<PageSettings />} />
          <Route path={routes.support} element={<PageSupport />} />
        </Route>
        <Route path={routes.login} element={<PageLogin />} />
        <Route element={<LoggedInContainer />}>
          <Route path={routes.home} element={<PageHome />} />
        </Route>
      </Routes>
    </HistoryRouter>
  </div>
);

export default MyRoutes;
