import { TextareaAutosize } from "@mui/material";
import { useFormContext } from "react-hook-form";
import FormElementInterface from "interfaces/FormElementInterface";
import Error from "../Error";
import Label from "../Label";
import { classNames } from "helpers/classNames";

interface Props extends FormElementInterface {
  className?: string;
  inputClassName?: string;
  minRows?: number;
  maxRows?: number;
  hideErrors?: boolean;
}

const Textarea = (props: Props) => {
  const { register, formState } = useFormContext();

  return (
    <div className={props.className}>
      <Label name={props.name} label={props.label} />
      <TextareaAutosize
        className={classNames(
          props.inputClassName || "border border-gray-300 rounded-2xl w-full px-3 text-gray-900 resize-none",
          formState.errors?.[props.name] && "border border-red-400",
        )}
        {...register(props.name)}
        minRows={props.minRows || 2}
        maxRows={props.maxRows}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
      />
      {!props.hideErrors && <Error name={props.name} />}
    </div>
  );
};

export default Textarea;
