export enum RunningState {
  Charging = 0,
  Idle = 1,
  Configuration = 2,
  Active = 3,
  Finished = 4,
}

export enum AlarmState {
  Inactive = 0,
  Active = 1,
  Acknowledged = 2,
}

export enum AlarmType {
  None = 0,
  LowBattery = 1,
  Tilt = 2,
  Flowchange = 3,
  Flowstop = 4,
  NoSignal = 5,
}

export enum TreatmentType {
  Unknown = 0,
  Hospital = 1,
  Outpatient = 2,
  HomeTreatment = 3,
}
