import { BagsizeInterface } from "interfaces/BagsizeInterface";
import { action, makeObservable, observable } from "mobx";
import { BaseStore } from "./BaseStore";

export class BagsizeStore extends BaseStore {
  bagsize: BagsizeInterface | null = null;
  bagsizes: BagsizeInterface[] | null = null;

  constructor() {
    super("BagsizeStore");

    makeObservable(this, {
      bagsize: observable,
      bagsizes: observable,
      getBagsize: action,
      getBagsizes: action,
    });

    this.initSessionStorage(this, ["bagsize", "bagsizes"]);
  }

  getBagsize = (bagsizeId: string) => {
    return this.get(`/bagsizes/${bagsizeId}`, "bagsize");
  };

  getBagsizes = (departmentId?: string) => {
    return this.get(`/bagsizes?departmentId=${departmentId}`, "bagsizes");
  };
}

export const bagsizeStore = new BagsizeStore();
