import { classNames } from "helpers/classNames";

interface PictogramProps {
  acknowledged?: boolean;
}

export function PictogramFlowChange({ acknowledged }: PictogramProps) {
  return (
    <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M61 121C94.1371 121 121 94.1371 121 61C121 27.8629 94.1371 1 61 1C27.8629 1 1 27.8629 1 61C1 94.1371 27.8629 121 61 121Z"
        fill={classNames({ "#FFEBEB": !acknowledged, "#FFE6B1": acknowledged })}
        stroke={classNames({ "#FC6F6C": !acknowledged, "#FBBF3E": acknowledged })}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M83.814 61H97.482" stroke="#535353" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.518 61H36.968" stroke="#535353" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.742 67.224V54.774" stroke="#535353" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M60.986 46.2739C60.7629 46.2835 60.5402 46.2462 60.3324 46.1646C60.1245 46.0829 59.936 45.9586 59.7791 45.7997C59.6222 45.6408 59.5003 45.4508 59.4212 45.2419C59.3421 45.0331 59.3076 44.81 59.32 44.587C59.6359 43.2716 60.2015 42.0291 60.986 40.927H61.014C61.799 42.0288 62.3646 43.2715 62.68 44.587C62.6923 44.81 62.6578 45.0331 62.5788 45.2419C62.4997 45.4508 62.3778 45.6408 62.2209 45.7997C62.0639 45.9586 61.8755 46.0829 61.6676 46.1646C61.4598 46.2462 61.2371 46.2835 61.014 46.2739H60.986Z"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.986 58.2739C60.7629 58.2835 60.5402 58.2462 60.3324 58.1646C60.1245 58.0829 59.936 57.9586 59.7791 57.7997C59.6222 57.6408 59.5003 57.4508 59.4212 57.2419C59.3421 57.0331 59.3076 56.81 59.32 56.587C59.6359 55.2716 60.2015 54.0291 60.986 52.927H61.014C61.799 54.0288 62.3646 55.2715 62.68 56.587C62.6923 56.81 62.6578 57.0331 62.5788 57.2419C62.4997 57.4508 62.3778 57.6408 62.2209 57.7997C62.0639 57.9586 61.8755 58.0829 61.6676 58.1646C61.4598 58.2462 61.2371 58.2835 61.014 58.2739H60.986Z"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.986 70.2739C60.7629 70.2835 60.5402 70.2462 60.3324 70.1646C60.1245 70.0829 59.936 69.9586 59.7791 69.7997C59.6222 69.6408 59.5003 69.4508 59.4212 69.2419C59.3421 69.0331 59.3076 68.81 59.32 68.587C59.6359 67.2716 60.2015 66.0291 60.986 64.927H61.014C61.799 66.0288 62.3646 67.2715 62.68 68.587C62.6923 68.81 62.6578 69.0331 62.5788 69.2419C62.4997 69.4508 62.3778 69.6408 62.2209 69.7997C62.0639 69.9586 61.8755 70.0829 61.6676 70.1646C61.4598 70.2462 61.2371 70.2835 61.014 70.2739H60.986Z"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.986 82.2739C60.7629 82.2835 60.5402 82.2462 60.3324 82.1646C60.1245 82.0829 59.936 81.9586 59.7791 81.7997C59.6222 81.6408 59.5003 81.4508 59.4212 81.2419C59.3421 81.0331 59.3076 80.81 59.32 80.587C59.6359 79.2716 60.2015 78.0291 60.986 76.927H61.014C61.799 78.0288 62.3646 79.2715 62.68 80.587C62.6923 80.81 62.6578 81.0331 62.5788 81.2419C62.4997 81.4508 62.3778 81.6408 62.2209 81.7997C62.0639 81.9586 61.8755 82.0829 61.6676 82.1646C61.4598 82.2462 61.2371 82.2835 61.014 82.2739H60.986Z"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M58.491 119.147V115.022H63.503V119.15" stroke="black" strokeMiterlimit="10" />
      <path d="M64.876 109.262H57.119V115.019H64.876V109.262Z" stroke="black" strokeMiterlimit="10" />
      <path
        d="M66.107 109.027H55.887C53.9985 109.027 52.186 108.283 50.843 106.955C49.4999 105.627 48.7346 103.823 48.713 101.935L47.989 39.075H74.006L73.282 101.935C73.2602 103.823 72.4946 105.627 71.1514 106.955C69.8082 108.283 67.9957 109.027 66.107 109.027Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M49.073 29.6639H72.925C73.6427 29.6639 74.331 29.949 74.8384 30.4565C75.3459 30.964 75.631 31.6523 75.631 32.3699V39.0699H46.367V32.3699C46.367 31.6523 46.6521 30.964 47.1596 30.4565C47.667 29.949 48.3553 29.6639 49.073 29.6639V29.6639Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path d="M64.876 3.94598V29.664H57.119V3.94598" stroke="black" strokeMiterlimit="10" />
      <path
        d="M71.057 85.502L70.872 101.602C70.8565 102.933 70.3167 104.205 69.3696 105.141C68.4225 106.077 67.1446 106.602 65.813 106.602H56.186C54.8543 106.602 53.5763 106.077 52.6291 105.141C51.6819 104.205 51.1418 102.933 51.126 101.602L50.942 85.502H71.057Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M118.556 30.4224H91.4435C90.9208 30.4224 90.4687 30.1642 90.2031 29.714C89.9375 29.2638 89.9301 28.7432 90.1828 28.2857L103.739 3.74425C103.996 3.27846 104.468 3.00037 105 3.00037C105.532 3.00037 106.003 3.27846 106.26 3.74425L119.817 28.2857C120.069 28.7432 120.062 29.2638 119.796 29.714C119.531 30.1642 119.079 30.4224 118.556 30.4224Z"
        fill="white"
      />
      <path
        d="M105 4.00037C104.881 4.00037 104.718 4.03982 104.614 4.22777L91.0581 28.7692C90.9577 28.9511 91.006 29.107 91.0643 29.2058C91.1226 29.3046 91.2357 29.4224 91.4435 29.4224H118.556C118.764 29.4224 118.877 29.3046 118.935 29.2058C118.993 29.107 119.042 28.9511 118.941 28.7692L105.385 4.22778C105.281 4.03982 105.118 4.00037 105 4.00037ZM105 2.00037C105.836 2.00037 106.672 2.42049 107.136 3.26073L120.692 27.8022C121.59 29.4286 120.414 31.4224 118.556 31.4224H91.4435C89.5855 31.4224 88.4091 29.4286 89.3075 27.8022L102.864 3.26073C103.328 2.42049 104.164 2.00037 105 2.00037Z"
        fill="#535353"
      />
      <path d="M105 13.156V19.744" stroke="#535353" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M105 25.3461V25.1411" stroke="#535353" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>
  );
}

export function PictogramFlowStop({ acknowledged }: PictogramProps) {
  return (
    <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M61 121C94.1371 121 121 94.1371 121 61C121 27.8629 94.1371 1 61 1C27.8629 1 1 27.8629 1 61C1 94.1371 27.8629 121 61 121Z"
        fill={classNames({ "#FFEBEB": !acknowledged, "#FFE6B1": acknowledged })}
        stroke={classNames({ "#FC6F6C": !acknowledged, "#FBBF3E": acknowledged })}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M58.625 119.147V115.022H63.637V119.15" stroke="black" strokeMiterlimit="10" />
      <path d="M65.01 109.262H57.253V115.019H65.01V109.262Z" stroke="black" strokeMiterlimit="10" />
      <path
        d="M66.241 109.027H56.021C54.1325 109.027 52.32 108.283 50.977 106.955C49.6339 105.627 48.8686 103.823 48.847 101.935L48.123 39.0751H74.14L73.416 101.935C73.3942 103.824 72.6286 105.627 71.2854 106.955C69.9422 108.283 68.1297 109.027 66.241 109.027Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M49.207 29.6641H73.059C73.7767 29.6641 74.465 29.9492 74.9724 30.4566C75.4799 30.9641 75.765 31.6524 75.765 32.3701V39.0701H46.501V32.3701C46.501 31.6524 46.7861 30.9641 47.2936 30.4566C47.801 29.9492 48.4893 29.6641 49.207 29.6641V29.6641Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path d="M65.01 3.94604V29.6641H57.253V3.94604" stroke="black" strokeMiterlimit="10" />
      <path
        d="M71.191 85.5021L71.006 101.602C70.9905 102.934 70.4507 104.205 69.5036 105.141C68.5565 106.077 67.2786 106.602 65.947 106.602H56.32C54.9883 106.602 53.7104 106.077 52.7631 105.141C51.8159 104.205 51.2758 102.934 51.26 101.602L51.076 85.5021H71.191Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M34.499 87.136L86.771 34.864"
        stroke="#535353"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.771 87.136L34.499 34.864"
        stroke="#535353"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.556 30.7113H91.4435C90.9208 30.7113 90.4687 30.4531 90.2031 30.0029C89.9375 29.5528 89.9301 29.0322 90.1828 28.5746L103.739 4.03319C103.996 3.5674 104.468 3.28931 105 3.28931C105.532 3.28931 106.003 3.5674 106.26 4.03319L119.817 28.5746C120.069 29.0322 120.062 29.5528 119.796 30.0029C119.531 30.4531 119.079 30.7113 118.556 30.7113Z"
        fill="white"
      />
      <path
        d="M105 4.28931C104.881 4.28931 104.718 4.32876 104.614 4.51671L91.0581 29.0581C90.9577 29.24 91.006 29.396 91.0643 29.4948C91.1226 29.5935 91.2357 29.7113 91.4435 29.7113H118.556C118.764 29.7113 118.877 29.5936 118.935 29.4948C118.993 29.396 119.042 29.24 118.941 29.0581L105.385 4.51672C105.281 4.32876 105.118 4.28931 105 4.28931ZM105 2.28931C105.836 2.28931 106.672 2.70943 107.136 3.54967L120.692 28.0911C121.59 29.7175 120.414 31.7113 118.556 31.7113H91.4435C89.5855 31.7113 88.4091 29.7175 89.3075 28.0911L102.864 3.54967C103.328 2.70943 104.164 2.28931 105 2.28931Z"
        fill="black"
      />
      <path d="M105 13.445V20.033" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M105 25.635V25.43" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>
  );
}

export function PictogramNoSignal({ acknowledged }: PictogramProps) {
  return (
    <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M61 121C94.1371 121 121 94.1371 121 61C121 27.8629 94.1371 1 61 1C27.8629 1 1 27.8629 1 61C1 94.1371 27.8629 121 61 121Z"
        fill={classNames({ "#FFEBEB": !acknowledged, "#FFE6B1": acknowledged })}
        stroke={classNames({ "#FC6F6C": !acknowledged, "#FBBF3E": acknowledged })}
        strokeMiterlimit="10"
      />
      <path
        d="M33.38 104.499C33.38 109.264 36.752 113.118 40.922 113.118"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M33.38 104.499V20.4598" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M87.2531 20.4598V104.499" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M40.921 113.119H79.708" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M33.38 20.459C33.38 15.695 36.752 11.84 40.922 11.84"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.251 20.459C87.251 15.695 83.877 11.84 79.709 11.84"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M40.921 11.8398H79.708" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M56.006 11.8409V9.68591" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M64.627 11.8409V9.68591" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M56.006 9.68481C56.006 9.39908 56.1194 9.12511 56.3214 8.92297C56.5233 8.72084 56.7973 8.6072 57.083 8.60693"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.624 9.68481C64.6241 9.5433 64.5964 9.40324 64.5423 9.27246C64.4882 9.14168 64.4089 9.02284 64.3089 8.92273C64.2089 8.82261 64.0901 8.74315 63.9594 8.68896C63.8286 8.63478 63.6885 8.60693 63.547 8.60693"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M57.083 8.60791H63.548" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M87.251 104.499C87.251 109.264 83.877 113.118 79.709 113.118"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M87.2531 23.6919H88.3301" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M88.33 15.0719V25.8459" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M88.328 15.0719C88.328 14.7863 88.4415 14.5123 88.6434 14.3103C88.8454 14.1083 89.1193 13.9949 89.405 13.9949"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.328 25.8469C88.3279 25.9884 88.3557 26.1284 88.4097 26.2592C88.4638 26.3899 88.5431 26.5087 88.6431 26.6088C88.7432 26.7088 88.8619 26.7881 88.9927 26.8422C89.1234 26.8962 89.2635 26.9241 89.405 26.924"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M89.407 13.9949H90.484" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M89.407 26.9238H90.484" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M91.561 25.8459V15.0719" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M91.5599 15.0719C91.5601 14.9304 91.5323 14.7903 91.4782 14.6595C91.4241 14.5288 91.3448 14.4101 91.2448 14.3101C91.1448 14.21 91.026 14.1306 90.8953 14.0765C90.7645 14.0225 90.6244 13.9947 90.4829 13.9949"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.5599 25.8469C91.5601 25.9884 91.5323 26.1284 91.4782 26.2592C91.4241 26.3899 91.3448 26.5087 91.2448 26.6088C91.1448 26.7088 91.026 26.7881 90.8953 26.8422C90.7645 26.8962 90.6244 26.9241 90.4829 26.924"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M35.959 37.9028V105.781" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M84.444 104.703V37.9028" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M84.4421 104.704C84.4421 107.681 82.5141 110.091 80.1321 110.091"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.958 104.704C35.9576 105.411 36.0967 106.112 36.3672 106.766C36.6378 107.42 37.0346 108.014 37.5349 108.514C38.0352 109.014 38.6293 109.411 39.283 109.682C39.9368 109.952 40.6374 110.091 41.345 110.091"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M41.3459 110.091H80.1329" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M40.807 26.0099C40.7886 26.2863 40.88 26.5589 41.0615 26.7683C41.2429 26.9777 41.4997 27.1069 41.776 27.1279"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M41.776 27.1289H45.655" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M46.732 26.0099C46.7504 26.2865 46.6589 26.5593 46.4772 26.7687C46.2955 26.9781 46.0384 27.1072 45.762 27.1279"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M40.806 26.0088V12.0438" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M46.733 26.0088V12.0438" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.137 93.1339H31.521" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.137 84.5139H31.521" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.444 92.0569V85.5919" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M30.443 85.5908C30.443 85.3052 30.5565 85.0312 30.7585 84.8292C30.9605 84.6272 31.2344 84.5138 31.52 84.5138"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.443 92.0559C30.4427 92.1975 30.4704 92.3377 30.5244 92.4686C30.5784 92.5995 30.6577 92.7185 30.7578 92.8187C30.8578 92.9189 30.9766 92.9985 31.1074 93.0527C31.2382 93.107 31.3784 93.1349 31.52 93.1349"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M38.113 29.2828V41.1348" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M40.269 41.1348V29.2828" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M38.1119 41.1349C38.1118 41.2764 38.1396 41.4165 38.1937 41.5472C38.2477 41.678 38.3271 41.7967 38.4271 41.8967C38.5272 41.9968 38.6459 42.0762 38.7767 42.1302C38.9074 42.1843 39.0475 42.212 39.189 42.2119"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.267 41.1349C40.2672 41.2764 40.2394 41.4165 40.1854 41.5472C40.1313 41.678 40.0519 41.7967 39.9519 41.8967C39.8519 41.9968 39.7331 42.0762 39.6024 42.1302C39.4716 42.1843 39.3315 42.212 39.1901 42.2119"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M82.288 41.1348V29.2828" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M80.134 29.2828V41.1348" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M80.1321 41.1349C80.1321 41.2764 80.1599 41.4165 80.2141 41.5472C80.2683 41.678 80.3478 41.7968 80.4479 41.8969C80.548 41.9969 80.6668 42.0762 80.7976 42.1302C80.9284 42.1843 81.0685 42.212 81.21 42.2119"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.287 41.1349C82.2871 41.2764 82.2593 41.4165 82.2053 41.5472C82.1512 41.678 82.0718 41.7967 81.9718 41.8967C81.8718 41.9968 81.753 42.0762 81.6223 42.1302C81.4915 42.1843 81.3514 42.212 81.21 42.2119"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.199 29.2828H85.145C85.5038 29.2958 85.8531 29.4022 86.1582 29.5914C86.4634 29.7807 86.7138 30.0463 86.8849 30.3619C87.0153 30.5602 87.1284 30.7693 87.223 30.9869"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M40.269 41.1359H80.134" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M87.598 93.4929H89.214" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M87.598 84.8739H89.214" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M90.291 92.4159V85.9509" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M90.291 85.9509C90.291 85.6653 90.1775 85.3913 89.9755 85.1893C89.7735 84.9874 89.4996 84.8739 89.214 84.8739"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.291 92.4149C90.2911 92.5564 90.2633 92.6965 90.2092 92.8273C90.1552 92.958 90.0759 93.0767 89.9758 93.1768C89.8758 93.2768 89.757 93.3561 89.6263 93.4102C89.4956 93.4642 89.3555 93.4921 89.214 93.4919"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.2 29.2828H35.0211C34.6594 29.2931 34.3066 29.3982 33.9983 29.5875C33.6899 29.7769 33.4368 30.044 33.264 30.3619"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.112 40.0579C38.112 38.2719 35.589 36.8258 33.802 36.8258"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.287 40.0579C82.287 38.2719 85.1669 36.6769 86.9529 36.6769"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105 33C113.837 33 121 25.8366 121 17C121 8.16344 113.837 1 105 1C96.1634 1 89 8.16344 89 17C89 25.8366 96.1634 33 105 33Z"
        fill="white"
      />
      <path
        d="M105 32C113.284 32 120 25.2843 120 17C120 8.71573 113.284 2 105 2C96.7157 2 90 8.71573 90 17C90 25.2843 96.7157 32 105 32Z"
        stroke="#535353"
        strokeWidth="2"
      />
      <path
        d="M103.481 15.58H105.612V22.464"
        stroke="#535353"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M103 23.824H108" stroke="#535353" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M105.5 13C106.328 13 107 12.3284 107 11.5C107 10.6716 106.328 10 105.5 10C104.672 10 104 10.6716 104 11.5C104 12.3284 104.672 13 105.5 13Z"
        fill="#535353"
      />
      <path
        d="M51.697 48.968L70.378 71.385"
        stroke="#535353"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.19 59.48C68.4187 60.0796 69.5676 60.8304 70.61 61.715"
        stroke="#535353"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.288 61.7861C53.5111 59.9273 56.1868 58.6904 59.043 58.201"
        stroke="#535353"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.566 51.271C60.7796 51.0121 64.0124 51.4033 67.0716 52.4213C70.1307 53.4392 72.9533 55.0631 75.371 57.196"
        stroke="#535353"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.631 57.058C48.7117 55.2188 51.0975 53.7569 53.681 52.738"
        stroke="#535353"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.801 66.516C57.3238 65.4341 59.1455 64.8529 61.0135 64.8529C62.8815 64.8529 64.7032 65.4341 66.226 66.516"
        stroke="#535353"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PictogramTilt({ acknowledged }: PictogramProps) {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60.001 120C93.1381 120 120.001 93.1371 120.001 60C120.001 26.8629 93.1381 0 60.001 0C26.8639 0 0.000976562 26.8629 0.000976562 60C0.000976562 93.1371 26.8639 120 60.001 120Z"
        fill={classNames({ "#FFEBEB": !acknowledged, "#FFE6B1": acknowledged })}
      />
      <path
        d="M60.001 119.5C92.8619 119.5 119.501 92.8609 119.501 60C119.501 27.1391 92.8619 0.5 60.001 0.5C27.14 0.5 0.500977 27.1391 0.500977 60C0.500977 92.8609 27.14 119.5 60.001 119.5Z"
        stroke={classNames({ "#FC6F6C": !acknowledged, "#FBBF3E": acknowledged })}
      />
      <path
        d="M92.8973 32.727L68.0077 18.357C64.5975 16.3881 60.2369 17.5565 58.268 20.9668L25.898 77.0332C23.9291 80.4435 25.0975 84.8041 28.5077 86.773L53.3973 101.143C56.8075 103.112 61.1682 101.943 63.1371 98.5332L95.5071 42.4668C97.476 39.0565 96.3075 34.6959 92.8973 32.727Z"
        stroke="#535353"
        strokeMiterlimit="10"
      />
      <path
        d="M90.59 46.9833L61.405 97.5332C60.7247 98.7115 59.6042 99.5712 58.29 99.9234C56.9758 100.276 55.5756 100.091 54.3973 99.4109L29.5077 85.0409C28.3295 84.3606 27.4697 83.2401 27.1175 81.9259C26.7654 80.6117 26.9497 79.2115 27.63 78.0332L56.815 27.4833L90.59 46.9833Z"
        stroke="#535353"
        strokeMiterlimit="10"
      />
      <path
        d="M68.8357 18.835L72.2132 20.785L67.3762 29.1629C67.2518 29.3783 67.0469 29.5355 66.8066 29.5999C66.5663 29.6643 66.3103 29.6306 66.0948 29.5062L64.342 28.4942C64.1265 28.3698 63.9693 28.165 63.9049 27.9247C63.8406 27.6844 63.8743 27.4283 63.9987 27.2129L68.8357 18.835Z"
        stroke="#535353"
        strokeMiterlimit="10"
      />
      <path
        d="M69.3437 23.7471L67.7017 22.7991C67.2234 22.5229 66.6118 22.6868 66.3357 23.1651L64.0302 27.1583C63.754 27.6366 63.9179 28.2482 64.3962 28.5244L66.0382 29.4724C66.5165 29.7485 67.128 29.5846 67.4042 29.1063L69.7097 25.1131C69.9858 24.6348 69.822 24.0232 69.3437 23.7471Z"
        stroke="#535353"
        strokeMiterlimit="10"
      />
      <path
        d="M89.9452 33.9982L84.891 31.0802C83.9345 30.5279 82.7113 30.8557 82.159 31.8123L80.142 35.3058C79.5897 36.2624 79.9175 37.4856 80.874 38.0378L85.9282 40.9558C86.8848 41.5081 88.1079 41.1804 88.6602 40.2238L90.6772 36.7303C91.2295 35.7737 90.9018 34.5505 89.9452 33.9982Z"
        stroke="#535353"
        strokeMiterlimit="10"
      />
      <path
        d="M81.8922 51.9464L56.8676 37.4984L31.0206 82.2667L56.0452 96.7147L81.8922 51.9464Z"
        stroke="#535353"
        strokeMiterlimit="10"
      />
      <path
        d="M46.1091 86.7126L44.649 85.8696C44.1487 85.5807 43.5089 85.7521 43.2201 86.2524L41.5161 89.2038C41.2273 89.7041 41.3987 90.3439 41.899 90.6327L43.3591 91.4757C43.8594 91.7645 44.4991 91.5931 44.7879 91.0928L46.4919 88.1414C46.7808 87.6411 46.6094 87.0014 46.1091 86.7126Z"
        stroke="#535353"
        strokeMiterlimit="10"
      />
      <path
        d="M99.9487 39.1075L98.7935 38.4405C98.395 38.2105 97.8856 38.347 97.6556 38.7454L93.4886 45.9629C93.2585 46.3613 93.395 46.8708 93.7935 47.1008L94.9487 47.7678C95.3472 47.9978 95.8566 47.8613 96.0866 47.4629L100.254 40.2454C100.484 39.847 100.347 39.3376 99.9487 39.1075Z"
        stroke="#535353"
        strokeMiterlimit="10"
      />
      <path
        d="M89.3481 46.2664L90.8204 47.1164C91.0178 47.2297 91.1909 47.3809 91.3298 47.5613C91.4687 47.7417 91.5707 47.9477 91.6299 48.1675C91.689 48.3873 91.7043 48.6167 91.6747 48.8424C91.6451 49.0681 91.5713 49.2858 91.4574 49.483L89.0729 53.613L86.0981 51.8955L89.3481 46.2664Z"
        stroke="#535353"
        strokeMiterlimit="10"
      />
      <path
        d="M58.0006 28.1678L59.8141 29.2148L55.0641 37.4421C54.9315 37.6718 54.713 37.8394 54.4569 37.908C54.2007 37.9767 53.9277 37.9407 53.698 37.8081L53.6166 37.7611C53.387 37.6285 53.2194 37.4101 53.1507 37.1539C53.0821 36.8977 53.118 36.6248 53.2506 36.3951L58.0006 28.1678Z"
        stroke="#535353"
        strokeMiterlimit="10"
      />
      <path
        d="M87.5338 45.2189L89.3473 46.2659L84.5973 54.4931C84.4647 54.7228 84.2462 54.8904 83.9901 54.959C83.7339 55.0277 83.4609 54.9917 83.2312 54.8591L83.1498 54.8121C82.9202 54.6795 82.7526 54.4611 82.6839 54.2049C82.6153 53.9488 82.6512 53.6758 82.7838 53.4461L87.5338 45.2189Z"
        stroke="#535353"
        strokeMiterlimit="10"
      />
      <path
        d="M87.5338 45.2188L59.8141 29.2148L55.4906 36.7034L83.2103 52.7074L87.5338 45.2188Z"
        stroke="#535353"
        strokeMiterlimit="10"
      />
      <path
        d="M56.5821 27.3488L57.9998 28.1673L54.7498 33.7964L51.833 32.1124L54.2175 27.9824C54.447 27.5848 54.8251 27.2947 55.2686 27.1758C55.712 27.057 56.1845 27.1192 56.5821 27.3488V27.3488Z"
        stroke="#535353"
        strokeMiterlimit="10"
      />
      <path
        d="M60.501 9.52698V111.472"
        stroke="#535353"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="6 6"
      />
      <path
        d="M100.749 57.9499C101.213 67.2695 98.4701 76.4667 92.9766 84.0093C87.483 91.5519 79.5709 96.9845 70.5584 99.402"
        stroke="#535353"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path d="M94.8776 60.5238L99.4004 49.446L106.735 58.9009L94.8776 60.5238Z" fill="#535353" />
      <path
        d="M117.557 29.4224H90.4445C89.9218 29.4224 89.4697 29.1642 89.204 28.714C88.9384 28.2638 88.931 27.7432 89.1838 27.2857L102.74 2.74425C102.997 2.27846 103.469 2.00037 104.001 2.00037C104.533 2.00037 105.004 2.27846 105.261 2.74425L118.818 27.2857C119.07 27.7432 119.063 28.2638 118.797 28.714C118.532 29.1642 118.08 29.4224 117.557 29.4224Z"
        fill="white"
      />
      <path
        d="M104.001 3.00037C103.882 3.00037 103.719 3.03982 103.615 3.22777L90.0591 27.7692C89.9586 27.9511 90.007 28.107 90.0653 28.2058C90.1236 28.3046 90.2367 28.4224 90.4445 28.4224H117.557C117.765 28.4224 117.878 28.3046 117.936 28.2058C117.994 28.107 118.043 27.9511 117.942 27.7692L104.386 3.22778C104.282 3.03982 104.119 3.00037 104.001 3.00037ZM104.001 1.00037C104.837 1.00037 105.673 1.42049 106.137 2.26073L119.693 26.8022C120.591 28.4286 119.415 30.4224 117.557 30.4224H90.4445C88.5864 30.4224 87.41 28.4286 88.3084 26.8022L101.865 2.26073C102.329 1.42049 103.165 1.00037 104.001 1.00037Z"
        fill="#535353"
      />
      <path d="M104.001 12.156V18.744" stroke="#535353" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M104.001 24.3461V24.1411" stroke="#535353" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>
  );
}

export function PictogramRunning() {
  return (
    <div>
      <img src="/assets/animations/drip.gif" alt="Running" className="w-[122px] mx-auto" />
    </div>
  );
}

export function PictogramIdle() {
  return (
    <svg width="121" height="122" viewBox="0 0 121 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60.5 121C93.6371 121 120.5 94.1371 120.5 61C120.5 27.8629 93.6371 1 60.5 1C27.3629 1 0.5 27.8629 0.5 61C0.5 94.1371 27.3629 121 60.5 121Z"
        fill="#E9FCF7"
        stroke="#B6D0C9"
        strokeMiterlimit="10"
      />
      <path d="M57.9909 118.566V114.441H63.003V118.569" stroke="black" strokeMiterlimit="10" />
      <path d="M64.376 108.681H56.619V114.438H64.376V108.681Z" stroke="black" strokeMiterlimit="10" />
      <path
        d="M65.607 108.446H55.3869C53.4984 108.446 51.686 107.702 50.3429 106.374C48.9999 105.046 48.2346 103.242 48.213 101.354L47.489 38.494H73.506L72.782 101.354C72.7601 103.242 71.9945 105.046 70.6513 106.374C69.3081 107.702 67.4956 108.446 65.607 108.446Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M48.5729 29.083H72.4249C73.1426 29.083 73.8309 29.3681 74.3384 29.8755C74.8458 30.383 75.1309 31.0713 75.1309 31.789V38.489H45.8669V31.789C45.8669 31.0713 46.152 30.383 46.6595 29.8755C47.167 29.3681 47.8553 29.083 48.5729 29.083V29.083Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path d="M64.3759 3.36499V29.083H56.6189V3.36499" stroke="black" strokeMiterlimit="10" />
      <path
        d="M70.557 84.921L70.372 101.021C70.3564 102.352 69.8166 103.624 68.8695 104.56C67.9225 105.496 66.6445 106.021 65.313 106.021H55.6859C54.3543 106.021 53.0763 105.496 52.1291 104.56C51.1818 103.624 50.6417 102.353 50.6259 101.021L50.4419 84.921H70.557Z"
        stroke="black"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export function PictogramNew() {
  return (
    <svg width="123" height="122" viewBox="0 0 123 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M61 121.42C94.1371 121.42 121 94.5571 121 61.42C121 28.2829 94.1371 1.42001 61 1.42001C27.8629 1.42001 1 28.2829 1 61.42C1 94.5571 27.8629 121.42 61 121.42Z"
        fill="#DBEFFF"
        stroke="#82B8E2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.986 49.694C60.7629 49.7035 60.5402 49.6663 60.3324 49.5846C60.1245 49.5029 59.936 49.3786 59.7791 49.2197C59.6222 49.0608 59.5003 48.8709 59.4212 48.662C59.3421 48.4531 59.3076 48.2301 59.32 48.0071C59.6359 46.6917 60.2015 45.4491 60.986 44.347H61.014C61.799 45.4489 62.3646 46.6915 62.68 48.0071C62.6923 48.2301 62.6579 48.4531 62.5788 48.662C62.4997 48.8709 62.3778 49.0608 62.2209 49.2197C62.0639 49.3786 61.8755 49.5029 61.6676 49.5846C61.4598 49.6663 61.2371 49.7035 61.014 49.694H60.986Z"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M58.491 119.566V115.441H63.503V119.569" stroke="black" strokeMiterlimit="10" />
      <path d="M64.876 109.681H57.119V115.438H64.876V109.681Z" stroke="black" strokeMiterlimit="10" />
      <path
        d="M66.107 109.446H55.887C53.9984 109.446 52.186 108.702 50.843 107.374C49.4999 106.046 48.7346 104.242 48.713 102.354L47.989 39.494H74.006L73.282 102.354C73.2602 104.243 72.4946 106.046 71.1514 107.374C69.8082 108.702 67.9957 109.446 66.107 109.446Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M49.073 30.083H72.925C73.6427 30.083 74.331 30.3681 74.8384 30.8756C75.3459 31.3831 75.631 32.0713 75.631 32.789V39.489H46.367V32.789C46.367 32.0713 46.6521 31.3831 47.1596 30.8756C47.667 30.3681 48.3553 30.083 49.073 30.083V30.083Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path d="M64.876 4.36502V30.083H57.119V4.36502" stroke="black" strokeMiterlimit="10" />
      <path
        d="M71.057 85.921L70.872 102.021C70.8565 103.352 70.3167 104.624 69.3696 105.56C68.4225 106.496 67.1446 107.021 65.813 107.021H56.186C54.8543 107.021 53.5763 106.496 52.6291 105.56C51.6819 104.624 51.1418 103.353 51.126 102.021L50.942 85.921H71.057Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M105.498 33.275C114.335 33.275 121.498 26.1116 121.498 17.275C121.498 8.43847 114.335 1.27502 105.498 1.27502C96.6614 1.27502 89.498 8.43847 89.498 17.275C89.498 26.1116 96.6614 33.275 105.498 33.275Z"
        fill="white"
        stroke="#535353"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M105.498 9.77502V24.775" stroke="#535353" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M112.998 17.275H97.998" stroke="#535353" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function PictogramComplete() {
  return (
    <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M61 121C94.1371 121 121 94.1371 121 61C121 27.8629 94.1371 1 61 1C27.8629 1 1 27.8629 1 61C1 94.1371 27.8629 121 61 121Z"
        fill="#DBEFFF"
        stroke="#82B8E2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M58.491 119.147V115.022H63.503V119.15" stroke="black" strokeMiterlimit="10" />
      <path d="M64.876 109.262H57.119V115.019H64.876V109.262Z" stroke="black" strokeMiterlimit="10" />
      <path
        d="M66.107 109.027H55.887C53.9985 109.027 52.186 108.283 50.843 106.955C49.4999 105.627 48.7346 103.823 48.713 101.935L47.989 39.075H74.006L73.282 101.935C73.2602 103.824 72.4946 105.627 71.1514 106.955C69.8082 108.283 67.9957 109.027 66.107 109.027Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M49.073 29.664H72.925C73.6427 29.664 74.331 29.9491 74.8384 30.4566C75.3459 30.964 75.631 31.6523 75.631 32.37V39.07H46.367V32.37C46.367 31.6523 46.6521 30.964 47.1596 30.4566C47.667 29.9491 48.3553 29.664 49.073 29.664V29.664Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path d="M64.876 3.94601V29.664H57.119V3.94601" stroke="black" strokeMiterlimit="10" />
      <path
        d="M71.057 85.502L70.872 101.602C70.8565 102.933 70.3167 104.205 69.3696 105.141C68.4225 106.077 67.1446 106.602 65.813 106.602H56.186C54.8543 106.602 53.5763 106.077 52.6291 105.141C51.6819 104.205 51.1418 102.934 51.126 101.602L50.942 85.502H71.057Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M105 33C113.837 33 121 25.8366 121 17C121 8.16344 113.837 1 105 1C96.1634 1 89 8.16344 89 17C89 25.8366 96.1634 33 105 33Z"
        fill="white"
        stroke="#535353"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.995 17L102.978 23.108C103.036 23.2113 103.119 23.2982 103.219 23.3601C103.32 23.4221 103.435 23.4571 103.553 23.4618C103.672 23.4665 103.789 23.4408 103.894 23.3871C104 23.3333 104.089 23.2534 104.155 23.155L111.995 10"
        fill="white"
      />
      <path
        d="M97.995 17L102.978 23.108C103.036 23.2113 103.119 23.2982 103.219 23.3601C103.32 23.4221 103.435 23.4571 103.553 23.4618C103.672 23.4665 103.789 23.4408 103.894 23.3871C104 23.3333 104.089 23.2534 104.155 23.155L111.995 10"
        stroke="#535353"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PictogramLowBattery() {
  return (
    <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M61 121C94.1371 121 121 94.1371 121 61C121 27.8629 94.1371 1 61 1C27.8629 1 1 27.8629 1 61C1 94.1371 27.8629 121 61 121Z"
        fill="#DBEFFF"
        stroke="#82B8E2"
        strokeMiterlimit="10"
      />
      <path
        d="M33.38 104.499C33.38 109.264 36.752 113.118 40.922 113.118"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M33.38 104.499V20.46" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M87.2531 20.46V104.499" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M40.921 113.119H79.708" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M33.38 20.4591C33.38 15.6951 36.752 11.8401 40.922 11.8401"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.251 20.4591C87.251 15.6951 83.877 11.8401 79.709 11.8401"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M40.921 11.84H79.708" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M56.006 11.841V9.68604" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M64.627 11.841V9.68604" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M56.006 9.68494C56.006 9.39921 56.1194 9.12523 56.3214 8.9231C56.5233 8.72096 56.7973 8.60732 57.083 8.60706"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.624 9.68494C64.6241 9.54342 64.5964 9.40337 64.5423 9.27258C64.4882 9.1418 64.4089 9.02297 64.3089 8.92285C64.2089 8.82274 64.0901 8.74327 63.9594 8.68909C63.8286 8.6349 63.6885 8.60706 63.547 8.60706"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M57.083 8.60803H63.548" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M87.251 104.499C87.251 109.264 83.877 113.118 79.709 113.118"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M87.2531 23.692H88.3301" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M88.33 15.072V25.846" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M88.328 15.072C88.328 14.7864 88.4415 14.5124 88.6434 14.3104C88.8454 14.1084 89.1193 13.995 89.405 13.995"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.328 25.847C88.3279 25.9885 88.3557 26.1286 88.4097 26.2593C88.4638 26.39 88.5431 26.5089 88.6431 26.6089C88.7432 26.7089 88.8619 26.7882 88.9927 26.8423C89.1234 26.8964 89.2635 26.9242 89.405 26.9241"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M89.407 13.995H90.484" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M89.407 26.924H90.484" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M91.561 25.846V15.072" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M91.5599 15.072C91.5601 14.9306 91.5323 14.7904 91.4782 14.6597C91.4241 14.5289 91.3448 14.4102 91.2448 14.3102C91.1448 14.2101 91.026 14.1307 90.8953 14.0767C90.7645 14.0226 90.6244 13.9949 90.4829 13.995"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.5599 25.847C91.5601 25.9885 91.5323 26.1286 91.4782 26.2593C91.4241 26.39 91.3448 26.5089 91.2448 26.6089C91.1448 26.7089 91.026 26.7882 90.8953 26.8423C90.7645 26.8964 90.6244 26.9242 90.4829 26.9241"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M35.959 37.903V105.781" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M84.444 104.703V37.903" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M84.4421 104.704C84.4421 107.681 82.5141 110.091 80.1321 110.091"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.958 104.704C35.9576 105.412 36.0967 106.112 36.3672 106.766C36.6378 107.42 37.0346 108.014 37.5349 108.514C38.0352 109.014 38.6293 109.411 39.283 109.682C39.9368 109.952 40.6374 110.091 41.345 110.091"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M41.3459 110.091H80.1329" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M40.807 26.01C40.7886 26.2865 40.88 26.5591 41.0615 26.7684C41.2429 26.9778 41.4997 27.1071 41.776 27.1281"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M41.776 27.129H45.655" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M46.732 26.01C46.7504 26.2866 46.6589 26.5594 46.4772 26.7688C46.2955 26.9782 46.0384 27.1073 45.762 27.1281"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M40.806 26.0089V12.0439" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M46.733 26.0089V12.0439" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.137 93.134H31.521" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.137 84.514H31.521" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.444 92.057V85.592" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M30.443 85.5909C30.443 85.3053 30.5565 85.0313 30.7585 84.8293C30.9605 84.6274 31.2344 84.5139 31.52 84.5139"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.443 92.056C30.4427 92.1976 30.4704 92.3379 30.5244 92.4688C30.5784 92.5996 30.6577 92.7186 30.7578 92.8188C30.8578 92.9191 30.9766 92.9986 31.1074 93.0529C31.2382 93.1071 31.3784 93.135 31.52 93.135"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M38.113 29.283V41.135" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M40.269 41.135V29.283" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M38.1119 41.135C38.1118 41.2765 38.1396 41.4166 38.1937 41.5474C38.2477 41.6781 38.3271 41.7968 38.4271 41.8969C38.5272 41.9969 38.6459 42.0763 38.7767 42.1304C38.9074 42.1844 39.0475 42.2122 39.189 42.212"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.267 41.135C40.2672 41.2765 40.2394 41.4166 40.1854 41.5474C40.1313 41.6781 40.0519 41.7968 39.9519 41.8969C39.8519 41.9969 39.7331 42.0763 39.6024 42.1304C39.4716 42.1844 39.3315 42.2122 39.1901 42.212"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M82.288 41.135V29.283" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M80.134 29.283V41.135" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M80.1321 41.135C80.1321 41.2765 80.1599 41.4166 80.2141 41.5474C80.2683 41.6781 80.3478 41.797 80.4479 41.897C80.548 41.997 80.6668 42.0763 80.7976 42.1304C80.9284 42.1844 81.0685 42.2122 81.21 42.212"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.287 41.135C82.2871 41.2765 82.2593 41.4166 82.2053 41.5474C82.1512 41.6781 82.0718 41.7968 81.9718 41.8969C81.8718 41.9969 81.753 42.0763 81.6223 42.1304C81.4915 42.1844 81.3514 42.2122 81.21 42.212"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.199 29.283H85.145C85.5038 29.2959 85.8531 29.4023 86.1582 29.5916C86.4634 29.7808 86.7138 30.0464 86.8849 30.3621C87.0153 30.5603 87.1284 30.7694 87.223 30.9871"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M40.269 41.136H80.134" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M87.598 93.493H89.214" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M87.598 84.874H89.214" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M90.291 92.416V85.951" stroke="#535353" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M90.291 85.951C90.291 85.6654 90.1775 85.3914 89.9755 85.1895C89.7735 84.9875 89.4996 84.874 89.214 84.874"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.291 92.415C90.2911 92.5565 90.2633 92.6967 90.2092 92.8274C90.1552 92.9581 90.0759 93.0768 89.9758 93.1769C89.8758 93.2769 89.757 93.3562 89.6263 93.4103C89.4956 93.4644 89.3555 93.4922 89.214 93.4921"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.2 29.283H35.0211C34.6594 29.2932 34.3066 29.3983 33.9983 29.5876C33.6899 29.777 33.4368 30.0441 33.264 30.3621"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.112 40.058C38.112 38.272 35.589 36.8259 33.802 36.8259"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.287 40.058C82.287 38.272 85.1669 36.677 86.9529 36.677"
        stroke="#535353"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.065 52.737H43.934C42.8294 52.737 41.934 53.6324 41.934 54.737V67.181C41.934 68.2856 42.8294 69.181 43.934 69.181H76.065C77.1696 69.181 78.065 68.2856 78.065 67.181V54.737C78.065 53.6324 77.1696 52.737 76.065 52.737Z"
        stroke="#535353"
        strokeWidth="2"
      />
      <path d="M81.065 57.459H79.065V64.459H81.065V57.459Z" fill="white" />
      <path d="M80.065 58.459V63.459V58.459Z" stroke="#535353" strokeWidth="2" />
      <path
        d="M47.934 55.737H45.934C45.3817 55.737 44.934 56.1847 44.934 56.737V65.181C44.934 65.7333 45.3817 66.181 45.934 66.181H47.934C48.4863 66.181 48.934 65.7333 48.934 65.181V56.737C48.934 56.1847 48.4863 55.737 47.934 55.737Z"
        fill="#FC6F6C"
      />
      <path
        d="M105 33C113.837 33 121 25.8366 121 17C121 8.16344 113.837 1 105 1C96.1634 1 89 8.16344 89 17C89 25.8366 96.1634 33 105 33Z"
        fill="white"
      />
      <path
        d="M105 32C113.284 32 120 25.2843 120 17C120 8.71573 113.284 2 105 2C96.7157 2 90 8.71573 90 17C90 25.2843 96.7157 32 105 32Z"
        stroke="#535353"
        strokeWidth="2"
      />
      <path
        d="M103.481 15.58H105.612V22.464"
        stroke="#535353"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M103 23.824H108" stroke="#535353" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M105.5 13C106.328 13 107 12.3284 107 11.5C107 10.6716 106.328 10 105.5 10C104.672 10 104 10.6716 104 11.5C104 12.3284 104.672 13 105.5 13Z"
        fill="#535353"
      />
    </svg>
  );
}
