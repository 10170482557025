import { AxiosError, AxiosResponse } from "axios";
import { StatusCodes } from "http-status-codes";
import baseRequest from "./BaseRequest";
import { history, routes } from "components/Routes";
import { toast } from "react-toastify";

const request = baseRequest;

request.interceptors.response.use(successResponseHandler, errorResponseHandler);

function successResponseHandler(response: AxiosResponse) {
  return response;
}

function errorResponseHandler(error: AxiosError): any {
  if (error.response?.status) {
    switch (error.response.status) {
      case StatusCodes.UNAUTHORIZED:
      case StatusCodes.FORBIDDEN:
        history.push(routes.login);
        break;
      case StatusCodes.BAD_REQUEST:
        toast((error as any).response.data.error, { type: "error" });
        break;
    }
  }
  return Promise.reject(error);
}

export default request;
