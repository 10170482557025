import { observer } from "mobx-react-lite";

const Support = observer(() => {
  return (
    <div>
      <div className="bg-white border-2 border-accent rounded-2xl px-12 py-8 flex flex-col">
        <div className="text-2xl font-medium mb-12">Support</div>
        <div className="flex flex-col lg:flex-row justify-between items-start gap-6">
          <div className="lg:w-1/3 space-y-6 mb-6">
            <div className="text-lg font-bold">Device support</div>
            <div>
              OSAA&reg; Innovation Office:
              <br />
              <br />
              Energivej 15, 1 th.
              <br />
              2750 Ballerup
              <br />
              Denmark
              <br />
              <br />
              <a href="tel:+4531715070" className="hover:underline">
                +45 31 71 50 70
              </a>
              <br />
              <br />
              <a href="mailto:support@iv2go.com" className="hover:underline">
                support@iv2go.com
              </a>
            </div>
          </div>
          <div className="lg:w-1/3 space-y-6 mb-6">
            <div className="text-lg font-bold">Dashboard support</div>
            <div>
              OSAA&reg; Innovation Office:
              <br />
              <br />
              Energivej 15, 1 th.
              <br />
              2750 Ballerup
              <br />
              Denmark
              <br />
              <br />
              <a href="tel:+4531715070" className="hover:underline">
                +45 31 71 50 70
              </a>
              <br />
              <br />
              <a href="mailto:support@iv2go.com" className="hover:underline">
                support@iv2go.com
              </a>
            </div>
          </div>
          <div className="lg:w-1/3 space-y-6 mb-6">
            <div className="text-lg font-bold">Maintenance and warranty</div>
            <div>
              OSAA&reg; Innovation Office:
              <br />
              <br />
              Energivej 15, 1 th.
              <br />
              2750 Ballerup
              <br />
              Denmark
              <br />
              <br />
              <a href="tel:+4531715070" className="hover:underline">
                +45 31 71 50 70
              </a>
              <br />
              <br />
              <a href="mailto:support@iv2go.com" className="hover:underline">
                support@iv2go.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Support;
