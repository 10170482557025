import Form from "components/Forms/Form";
import { YupInterface } from "interfaces/YupInterface";
import { observer } from "mobx-react-lite";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
//import { ResponsiveLine } from "@nivo/line";

type MonthStats = {
  month: string | number;
  [key: string]: string | number;
};

type WeekStats = {
  week: string | number;
  [key: string]: string | number;
};

const Statistics = observer(() => {
  const handleSubmit = () => {};

  const validation = (yup: YupInterface) => {
    return {
      title: yup.string().required("Titel skal udfyldes"),
    };
  };

  let colors = ["#4C855D", "#3DC496", "#3B81B8", "#8991B2", "#EF8695", "#B55D98", "#B8683F", "#E36C38"];
  let theme = {
    textColor: "#111827",
    tooltip: {
      container: {
        fontSize: 12,
      },
    },
  };

  let bags = [
    "Fresenius Kabi 50ml",
    "Fresenius Kabi 100ml",
    "Fresenius Kabi 250ml",
    "Fresenius Kabi 500ml",
    "Baxter 50ml",
    "Baxter 100ml",
    "Baxter 250ml",
    "Baxter 500ml",
    "Baxter 1000ml",
  ];

  let weekKeys = ["1", "2", "3", "4", "5"];

  let weeks = weekKeys.map((week) => {
    let obj = {
      week,
      total: Math.round(Math.random() * 10),
    } as WeekStats;

    return obj;
  });

  let monthKeys = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let months = monthKeys.map((month) => {
    let obj = {
      month,
    } as MonthStats;

    bags.forEach((product: string) => {
      obj[product] = Math.round(Math.random() * 10);
    });

    return obj;
  });

  let devices = ["01", "02", "03", "04", "05", "06", "08", "09", "10"].map((id) => {
    return {
      id,
      value: Math.round(Math.random() * 100),
    };
  });

  let restamounts = [] as any;

  bags.forEach((product: string) => {
    restamounts.push({
      id: product,
      data: monthKeys.map((week, index) => {
        return {
          x: week,
          y: Math.round(Math.random() * 100),
        };
      }),
    });
  });

  // let restamountsAvg = restamounts.map((slice: any) => {
  //   let a = slice.data.map((d: any) => {
  //     return d.y;
  //   });
  //   return a.reduce((a: number, b: number) => a + b, 0) / a.length;
  // });
  // let restamountsTotalAvg = Math.round(
  //   restamountsAvg.reduce((a: number, b: number) => a + b, 0) / restamountsAvg.length,
  // );

  let totals = bags.map((bag) => {
    return {
      id: bag,
      label: bag,
      value: Math.round(Math.random() * 100),
    };
  });

  let totalsProducer = [
    {
      id: "Fresenius Kabi",
      label: "Fresenius Kabi",
      value: Math.round(Math.random() * 100),
    },
    {
      id: "Baxter",
      label: "Baxter",
      value: Math.round(Math.random() * 100),
    },
  ];

  let totalsSize = [
    {
      id: "50ml",
      label: "50ml",
      value: Math.round(Math.random() * 100),
    },
    {
      id: "100ml",
      label: "100ml",
      value: Math.round(Math.random() * 100),
    },
    {
      id: "250ml",
      label: "250ml",
      value: Math.round(Math.random() * 100),
    },
    {
      id: "500ml",
      label: "500ml",
      value: Math.round(Math.random() * 100),
    },
    {
      id: "1000ml",
      label: "1000ml",
      value: Math.round(Math.random() * 100),
    },
  ];

  const CenteredMetric = ({
    dataWithArc,
    centerX,
    centerY,
  }: { dataWithArc: object[]; centerX: number; centerY: number }) => {
    let total = 0;
    dataWithArc.forEach((datum: any) => {
      total += datum.value;
    });

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        className="text-5xl font-medium fill-gray-700"
      >
        {total}
      </text>
    );
  };

  return (
    <div className="space-y-8">
      <div className="bg-white border-2 border-accent rounded-2xl px-12 py-8 flex flex-col">
        <Form onSubmit={handleSubmit} validation={validation}>
          <div className="flex justify-between gap-6 border-b border-gray-200 pb-6 mb-6">
            <div className="text-2xl font-medium">Statistics</div>
          </div>
          <div className="space-y-12">
            <div>
              <div className="text-lg font-medium">Bag usage in 2022</div>
              <div className="w-full h-[500px]">
                <ResponsiveBar
                  theme={theme}
                  data={months}
                  keys={bags}
                  indexBy="month"
                  margin={{ top: 20, right: 160, bottom: 60, left: 60 }}
                  axisBottom={{
                    legend: "Months",
                    legendPosition: "middle",
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    legend: "Bags",
                    legendPosition: "middle",
                    legendOffset: -40,
                  }}
                  colors={colors}
                  labelSkipHeight={15}
                  legends={[
                    {
                      dataFrom: "keys",
                      anchor: "right",
                      direction: "column",
                      translateX: 140,
                      translateY: 0,
                      itemsSpacing: 0,
                      itemWidth: 120,
                      itemHeight: 20,
                      itemDirection: "left-to-right",
                      itemOpacity: 1,
                      symbolSize: 15,
                    },
                  ]}
                />
              </div>
            </div>
            <div>
              <div className="md:grid grid-cols-2 xl:grid-cols-3 gap-6">
                <div>
                  <div className="text-lg font-medium">Totals</div>
                  <div className="w-full h-[300px]">
                    <ResponsivePie
                      theme={theme}
                      data={totals}
                      margin={{ top: 30, right: 0, bottom: 30, left: 120 }}
                      innerRadius={0.5}
                      colors={colors}
                      activeOuterRadiusOffset={5}
                      arcLabelsSkipAngle={10}
                      enableArcLinkLabels={false}
                      arcLinkLabel="label"
                      arcLinkLabelsTextColor="#111827"
                      arcLinkLabelsThickness={2}
                      arcLinkLabelsColor={{ from: "color" }}
                      layers={["arcs", "arcLabels", "legends", "arcLinkLabels", CenteredMetric]}
                      legends={[
                        {
                          anchor: "left",
                          direction: "column",
                          translateX: -110,
                          translateY: 0,
                          itemsSpacing: 0,
                          itemWidth: 120,
                          itemHeight: 20,
                          itemDirection: "left-to-right",
                          itemOpacity: 1,
                          symbolSize: 15,
                        },
                      ]}
                    />
                  </div>
                </div>
                <div>
                  <div className="text-lg font-medium">Bag usage by manufacturer</div>
                  <div className="w-full h-[300px]">
                    <ResponsivePie
                      theme={theme}
                      data={totalsProducer}
                      margin={{ top: 30, right: 0, bottom: 30, left: 90 }}
                      innerRadius={0}
                      colors={colors}
                      activeOuterRadiusOffset={5}
                      arcLabelsSkipAngle={10}
                      enableArcLinkLabels={false}
                      arcLinkLabel="label"
                      arcLinkLabelsTextColor="#111827"
                      arcLinkLabelsThickness={2}
                      arcLinkLabelsColor={{ from: "color" }}
                      legends={[
                        {
                          anchor: "left",
                          direction: "column",
                          translateX: -80,
                          translateY: 0,
                          itemsSpacing: 0,
                          itemWidth: 120,
                          itemHeight: 20,
                          itemDirection: "left-to-right",
                          itemOpacity: 1,
                          symbolSize: 15,
                        },
                      ]}
                    />
                  </div>
                </div>
                <div>
                  <div className="text-lg font-medium">Bag usage by size</div>
                  <div className="w-full h-[300px]">
                    <ResponsivePie
                      theme={theme}
                      data={totalsSize}
                      margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
                      innerRadius={0}
                      colors={colors}
                      activeOuterRadiusOffset={5}
                      arcLabelsSkipAngle={10}
                      arcLinkLabel="label"
                      arcLinkLabelsTextColor="#111827"
                      arcLinkLabelsThickness={2}
                      arcLinkLabelsColor={{ from: "color" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="text-lg font-medium">Bag usage per device</div>
              <div className="w-full h-[500px]">
                <ResponsiveBar
                  theme={theme}
                  data={devices.sort((a, b) => a.value - b.value)}
                  indexBy="id"
                  keys={["value"]}
                  layout="horizontal"
                  margin={{ top: 60, right: 20, bottom: 20, left: 60 }}
                  axisTop={{
                    legend: "Bags",
                    legendPosition: "middle",
                    legendOffset: -40,
                  }}
                  axisLeft={{
                    legend: "Device",
                    legendPosition: "middle",
                    legendOffset: -40,
                  }}
                  axisBottom={null}
                  colors={colors}
                  colorBy="indexValue"
                  tooltip={({ id, value, formattedValue, label, color, indexValue }) => (
                    <div className="border border-gray-100 rounded shadow text-xs bg-white flex items-center gap-1 p-1.5">
                      <div className="flex items-center justify-between">
                        <span className="inline-block w-3 h-3 mr-1.5" style={{ backgroundColor: color }} />
                        Device #{indexValue}:
                      </div>
                      <div className="font-bold">{value}</div>
                    </div>
                  )}
                />
              </div>
            </div>
            <div>
              <div className="text-lg font-medium">Bag usage per week in 2023</div>
              <div className="w-full h-[500px]">
                <ResponsiveBar
                  theme={theme}
                  data={weeks}
                  keys={["total"]}
                  indexBy="week"
                  margin={{ top: 20, right: 160, bottom: 60, left: 60 }}
                  axisBottom={{
                    legend: "Weeks",
                    legendPosition: "middle",
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    legend: "Bags",
                    legendPosition: "middle",
                    legendOffset: -40,
                  }}
                  colors={["#3DC496"]}
                  labelSkipHeight={15}
                />
              </div>
            </div>
            {/* <div>
              <div className="text-lg font-medium">Amount of fluid left after treatment</div>
              <div className="w-full h-[500px]">
                <ResponsiveLine
                  theme={theme}
                  data={restamounts}
                  margin={{ top: 20, right: 160, bottom: 60, left: 60 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: 0,
                    max: 100,
                    stacked: false,
                    reverse: false,
                  }}
                  yFormat={(value) => `${value}%`}
                  curve="monotoneX"
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Week",
                    legendOffset: 40,
                    legendPosition: "middle",
                  }}
                  axisLeft={{
                    format: (value) => `${value}%`,
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Percent %",
                    legendOffset: -50,
                    legendPosition: "middle",
                  }}
                  pointSize={4}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor" }}
                  enableSlices="x"
                  colors={colors}
                  legends={[
                    {
                      anchor: "right",
                      direction: "column",
                      justify: false,
                      translateX: 140,
                      translateY: 0,
                      itemsSpacing: 0,
                      itemDirection: "left-to-right",
                      itemWidth: 120,
                      itemHeight: 20,
                      symbolSize: 15,
                    },
                  ]}
                  markers={[
                    {
                      axis: "y",
                      value: restamountsTotalAvg,
                      lineStyle: { stroke: "#6b7280", strokeWidth: 2 },
                      textStyle: { fontSize: 12 },
                      legend: "average",
                      legendOrientation: "vertical",
                    },
                  ]}
                />
              </div>
            </div> */}
          </div>
        </Form>
      </div>
    </div>
  );
});

export default Statistics;
