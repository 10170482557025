import { toast } from "react-toastify";

const Login = () => {
  const showToast = () => {
    toast("Sådan laver man en toast", { type: "success" });
  };

  return (
    <div>
      <div>
        <button type='button' onClick={showToast}>
          Toast
        </button>
      </div>
      Login
    </div>
  );
};

export default Login;
