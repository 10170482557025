import { history, routes } from "components/Routes";
import request from "helpers/Request";
import { action, makeObservable, observable } from "mobx";
import { baseRequestStore } from "./BaseRequestStore";
import { UserInterface } from "interfaces/UserInterface";
import { BaseStore } from "./BaseStore";
import { BearerTokenResponseInterface } from "interfaces/BearerTokenResponseInterface";
import { StatusCodes } from "http-status-codes";

class AuthStore extends BaseStore {
  user: UserInterface | null = null;

  constructor() {
    super("AuthStore");

    makeObservable(this, {
      user: observable,
      resetStore: action,
      authenticate: action,
      changePassword: action,
      logout: action,
      resetPassword: action,
      setPassword: action,
    });

    this.initSessionStorage(this, ["user"]);
  }

  resetStore = () => {
    this.user = null;
    baseRequestStore.resetStore();
  };

  authenticate = async (email: string, password: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        // send request
        const res = await request.post("auth/token", {
          username: email,
          password: password,
        });

        if (!res.data) {
          reject("Invalid email or password");
          return;
        }

        // pick data from response
        const responseBody = res.data as BearerTokenResponseInterface;

        // set data in requestStore
        baseRequestStore.bearerToken = responseBody.bearerToken;
        baseRequestStore.expires = responseBody.expires;
        baseRequestStore.renewToken = responseBody.renewToken!;

        await this.get("users/me", "user");
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  };

  changePassword = (currentPassword: string, newPassword: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        // send request
        const res = await request.post("auth/change-password", {
          currentPassword: currentPassword,
          newPassword: newPassword,
        });

        if (res.status === StatusCodes.UNAUTHORIZED) {
          reject("Invalid password");
          return;
        }

        // pick data from response
        const responseBody = res.data as BearerTokenResponseInterface;

        // set data in requestStore
        baseRequestStore.bearerToken = responseBody.bearerToken;
        baseRequestStore.expires = responseBody.expires;
        baseRequestStore.renewToken = responseBody.renewToken!;

        await this.get("users/me", "user");
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  };

  renewToken = async (renewToken: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await request.post("auth/renew-token", {
          renewToken: renewToken,
        });

        if (!res.data) {
          reject("Invalid renew token");
        }

        const responseBody = res.data as BearerTokenResponseInterface;

        // update bearer token data in requestStore
        baseRequestStore.bearerToken = responseBody.bearerToken;
        baseRequestStore.expires = responseBody.expires;

        await this.get("members/me", "admin");
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  };

  resetPassword = (email: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await request.post("auth/reset-password", {
          email: email,
        });

        if (res.status === StatusCodes.OK) {
          resolve(true);
        } else reject();
      } catch (error) {
        reject(error);
      }
    });
  };

  setPassword = (token: string, password: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await request.post("auth/set-password", {
          token: token,
          password: password,
        });

        if (res.status === StatusCodes.OK) {
          resolve(true);
        } else reject();
      } catch (error) {
        reject(error);
      }
    });
  };

  getDownloadToken = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await request.get("auth/download-token");
        if (res.data) {
          resolve(res.data);
        } else reject();
      } catch (error) {
        reject(error);
      }
    });
  };

  logout = () => {
    this.resetStore();
    history.push(routes.login);
  };

  get isLoggedIn() {
    return this.user !== null;
  }
}

export const authStore = new AuthStore();
