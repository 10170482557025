import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AlarmState, RunningState } from "enums";
import { DeviceInterface } from "interfaces/DeviceInterface";
import DeviceCard from "components/Cards/DeviceCard";
import DeviceCardDetails from "components/Cards/DeviceCardDetails";
import { deviceStore } from "stores/DeviceStore";
import { bagsizeStore } from "stores/BagsizeStore";

const Overview = observer(() => {
  const [expandedCard, setExpandedCard] = useState<DeviceInterface>();
  const devices = deviceStore.devices;

  useEffect(() => {
    const interval = setInterval(() => {
      deviceStore.getDevices(process.env.REACT_APP_DEPARTMENT_ID);
    }, 30000);

    deviceStore.getDevices(process.env.REACT_APP_DEPARTMENT_ID);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (expandedCard) {
      document.getElementById("scrollContainer")?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [expandedCard]);

  useEffect(() => {
    bagsizeStore.getBagsizes(process.env.REACT_APP_DEPARTMENT_ID);
  }, []);

  const collapseAll = () => {
    setExpandedCard(undefined);
  };

  let actionRequired =
    devices?.filter((device: DeviceInterface) => {
      return device.alarmState === AlarmState.Active || device.alarmState === AlarmState.Acknowledged;
    }) || [];

  let active =
    devices?.filter((device: DeviceInterface) => {
      return (
        device.runningState !== RunningState.Idle &&
        device.runningState !== RunningState.Charging &&
        device.alarmState === AlarmState.Inactive
      );
    }) || [];

  let inactive =
    devices?.filter((device: DeviceInterface) => {
      return (
        (device.runningState === RunningState.Idle || device.runningState === RunningState.Charging) &&
        device.alarmState === AlarmState.Inactive
      );
    }) || [];

  return (
    <div>
      {expandedCard && <DeviceCardDetails device={expandedCard} onCollapse={collapseAll} />}
      <div className="space-y-12">
        {actionRequired.length > 0 && (
          <div className="px-6 py-8 bg-white rounded-xl shadow-2xl shadow-accent/75">
            <div className="mb-5">
              <div className="text-2xl font-medium text-gray-900">Action required</div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-10">
              {actionRequired?.map((device: DeviceInterface) => (
                <DeviceCard
                  key={device.id}
                  device={device}
                  onExpand={() => setExpandedCard(device)}
                  onCollapse={collapseAll}
                />
              ))}
            </div>
          </div>
        )}

        {active.length > 0 && (
          <div className="px-6">
            <div className="mb-5">
              <div className="text-2xl font-medium text-gray-900">Active devices</div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-10">
              {active.map((device: DeviceInterface) => (
                <DeviceCard
                  key={device.id}
                  device={device}
                  onExpand={() => setExpandedCard(device)}
                  onCollapse={collapseAll}
                />
              ))}
            </div>
          </div>
        )}

        {inactive.length > 0 && (
          <div className="px-6">
            <div className="mb-5">
              <div className="text-2xl font-medium text-gray-900">Inactive</div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-10">
              {inactive?.map((device: DeviceInterface) => (
                <DeviceCard
                  key={device.id}
                  device={device}
                  onExpand={() => setExpandedCard(device)}
                  onCollapse={collapseAll}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default Overview;
