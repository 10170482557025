import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  PlusCircleIcon,
  SignalSlashIcon,
} from "@heroicons/react/24/outline";
import { Device } from "classes/Device";
import { AlarmType } from "enums";
import { classNames } from "helpers/classNames";

interface Props {
  device: Device;
  className?: string;
}

export default function DeviceCardIcon(props: Props) {
  if (!props.device.hasNoAlarm()) {
    if (props.device.hasAlarm(AlarmType.NoSignal)) {
      return <SignalSlashIcon className={classNames("h-8", props.className || "text-gray-600")} />;
    } else {
      return <ExclamationTriangleIcon className={classNames("h-8", props.className || "text-gray-600")} />;
    }
  } else if (!props.device.isIdle()) {
    if (props.device.getBatteryLevel() <= 10) {
      return <InformationCircleIcon className={classNames("h-8", props.className || "text-gray-600")} />;
    } else if (props.device.isComplete()) {
      return <CheckCircleIcon className={classNames("h-8", props.className || "text-gray-600")} />;
    } else if (props.device.isRunning() && !props.device.hasPatientInformation()) {
      return <PlusCircleIcon className={classNames("h-8", props.className || "text-gray-600")} />;
    }
  }

  return null;
}
