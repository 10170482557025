import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { TreatmentType } from "enums";
import { DeviceInterface } from "interfaces/DeviceInterface";
import DeviceCard from "components/Cards/DeviceCard";
import { deviceStore } from "stores/DeviceStore";
import DeviceCardDetails from "components/Cards/DeviceCardDetails";

const Locations = observer(() => {
  const [expandedCard, setExpandedCard] = useState<DeviceInterface>();
  const devices = deviceStore.devices;

  useEffect(() => {
    const interval = setInterval(() => {
      deviceStore.getDevices(process.env.REACT_APP_DEPARTMENT_ID);
    }, 30000);

    deviceStore.getDevices(process.env.REACT_APP_DEPARTMENT_ID);

    return () => clearInterval(interval);
  }, []);

  const collapseAll = () => {
    setExpandedCard(undefined);
  };

  let hospital =
    devices?.filter((device: DeviceInterface) => {
      return device.latestTreatment?.startTime && device.latestTreatment?.type === TreatmentType.Hospital;
    }) || [];

  let outpatient =
    devices?.filter((device: DeviceInterface) => {
      return device.latestTreatment?.startTime && device.latestTreatment?.type === TreatmentType.Outpatient;
    }) || [];

  let hometreatment =
    devices?.filter((device: DeviceInterface) => {
      return device.latestTreatment?.startTime && device.latestTreatment?.type === TreatmentType.HomeTreatment;
    }) || [];

  return (
    <div>
      {expandedCard && <DeviceCardDetails device={expandedCard} onCollapse={collapseAll} />}
      <div className="space-y-12">
        {hospital.length > 0 && (
          <div className="px-6">
            <div className="mb-5">
              <div className="text-2xl font-medium text-gray-900">Hospital</div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-10">
              {hospital?.map((device: DeviceInterface) => (
                <DeviceCard
                  key={device.id}
                  device={device}
                  onExpand={() => setExpandedCard(device)}
                  onCollapse={collapseAll}
                />
              ))}
            </div>
          </div>
        )}

        {outpatient.length > 0 && (
          <div className="px-6">
            <div className="mb-5">
              <div className="text-2xl font-medium text-gray-900">Outpatient</div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-10">
              {outpatient.map((device: DeviceInterface) => (
                <DeviceCard
                  key={device.id}
                  device={device}
                  onExpand={() => setExpandedCard(device)}
                  onCollapse={collapseAll}
                />
              ))}
            </div>
          </div>
        )}

        {hometreatment.length > 0 && (
          <div className="px-6">
            <div className="mb-5">
              <div className="text-2xl font-medium text-gray-900">Home treatment</div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-10">
              {hometreatment?.map((device: DeviceInterface) => (
                <DeviceCard
                  key={device.id}
                  device={device}
                  onExpand={() => setExpandedCard(device)}
                  onCollapse={collapseAll}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default Locations;
