import { TreatmentInterface, UpdateTreatmentInterface } from "interfaces/TreatmentInterface";
import { action, makeObservable, observable } from "mobx";
import { BaseStore } from "./BaseStore";

export class TreatmentStore extends BaseStore {
  treatment: TreatmentInterface | null = null;

  constructor() {
    super("TreatmentStore");

    makeObservable(this, {
      treatment: observable,
      updateTreatment: action,
    });

    this.initSessionStorage(this, ["treatment"]);
  }

  updateTreatment = async (deviceId: string, treatment: UpdateTreatmentInterface): Promise<boolean> => {
    try {
      await this.put(`/devices/${deviceId}/treatment`, treatment, "treatment");

      return true;
    } catch (error) {
      return false;
    }
  };
}

export const treatmentStore = new TreatmentStore();
