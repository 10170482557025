import { IconIv2goOutline, IconLogo, IconPoweredBy } from "components/SVG/Icons";
import { observer } from "mobx-react-lite";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import {
  ArrowRightOnRectangleIcon,
  BellIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  MapPinIcon,
  QuestionMarkCircleIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

const AppLayout = observer(() => {
  const [currentTime, setCurrentTime] = useState<Moment>(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="h-screen flex max-w-screen-3xl">
      <div className="flex-none w-[75px] xl:w-[225px] flex flex-col justify-between bg-accent rounded-xl m-4">
        <div>
          <div className="xl:w-24 mx-auto pt-20 pb-20 xl:pt-10">
            <IconLogo className="text-gray-700 rotate-90 -ml-2 xl:rotate-0 xl:ml-0" />
          </div>
          <div className="text-sm text-gray-700 space-y-2">
            <Link
              to="/"
              className="flex items-center justify-center xl:justify-start hover:bg-black/10 transition-colors duration-200 px-6 py-4"
            >
              <Squares2X2Icon className="h-6 xl:mr-4" />
              <span className="hidden xl:block">Overview</span>
            </Link>
            <Link
              to="/locations"
              className="flex items-center justify-center xl:justify-start hover:bg-black/10 transition-colors duration-200 px-6 py-4"
            >
              <MapPinIcon className="h-6 xl:mr-4" />
              <span className="hidden xl:block">Location</span>
            </Link>
            <button
              onClick={() => toast.info("Not available in demo")}
              className="w-full flex items-center justify-center xl:justify-start hover:bg-black/10 transition-colors duration-200 px-6 py-4"
            >
              <BellIcon className="h-6 xl:mr-4" />
              <span className="hidden xl:block">Notifications</span>
            </button>
            <Link
              to="/statistics"
              className="flex items-center justify-center xl:justify-start hover:bg-black/10 transition-colors duration-200 px-6 py-4"
            >
              <ChartBarIcon className="h-6 xl:mr-4" />
              <span className="hidden xl:block">Statistics</span>
            </Link>
          </div>
        </div>

        <div className="space-y-6">
          <div className="hidden xl:block">
            <div>
              <IconIv2goOutline className="text-accent-dark mx-auto" />
            </div>
            <IconPoweredBy className="text-gray-700 mx-auto mt-4" />
          </div>
          <div className="text-sm text-gray-700 space-y-2 bg-navigation-dark/40 rounded-xl py-6">
            <Link
              to="/settings"
              className="flex items-center justify-center xl:justify-start hover:bg-black/10 transition-colors duration-200 px-6 py-4"
            >
              <Cog6ToothIcon className="h-6 xl:mr-4" />
              <span className="hidden xl:block">Settings</span>
            </Link>
            <Link
              to="/support"
              className="flex items-center justify-center xl:justify-start hover:bg-black/10 transition-colors duration-200 px-6 py-4"
            >
              <QuestionMarkCircleIcon className="h-6 xl:mr-4" />
              <span className="hidden xl:block">Support</span>
            </Link>
            <Link
              to="/"
              className="flex items-center justify-center xl:justify-start hover:bg-black/10 transition-colors duration-200 px-6 py-4"
            >
              <ArrowRightOnRectangleIcon className="h-6 xl:mr-4" />
              <span className="hidden xl:block">Log out</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex-grow max-h-screen rounded-3xl bg-accent-light mt-4 mr-4 mb-4">
        <div className="flex flex-col h-full">
          <div className="flex-none flex justify-between p-4 ml-6">
            <div className="flex items-center gap-4">
              <div>
                <img src="/assets/images/Logo_Rigshospitalet_RGB_54px.png" alt="Rigshospitalet" />
              </div>
              <div>
                <div className="text-lg leading-normal font-medium text-gray-900">Rigshospitalet</div>
                <div className="text-lg leading-normal font-normal text-gray-600">Lever-, Mave- og Tarmsygdomme</div>
              </div>
            </div>
            <div className="text-right">
              <div className="text-lg leading-normal font-medium text-gray-900">{currentTime.format("HH:mm")}</div>
              <div className="text-lg leading-normal font-normal text-gray-600">
                {currentTime.format("D. MMMM YYYY")}
              </div>
            </div>
          </div>
          <div className="flex-grow overflow-y-scroll max-h-full p-4 relative" id="scrollContainer">
            <Outlet />
          </div>
        </div>
      </div>
      {/* <div className="absolute top-0 right-0 bg-gray-100">
        <div className="block sm:hidden">default</div>
        <div className="hidden sm:block md:hidden">sm</div>
        <div className="hidden md:block lg:hidden">md</div>
        <div className="hidden lg:block xl:hidden">lg</div>
        <div className="hidden xl:block 2xl:hidden">xl</div>
        <div className="hidden 2xl:block">2xl</div>
      </div> */}
    </div>
  );
});

export default AppLayout;
