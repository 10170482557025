import { DeviceInterface, UpdateDeviceInterface } from "interfaces/DeviceInterface";
import { action, makeObservable, observable } from "mobx";
import request from "helpers/Request";
import { BaseStore } from "./BaseStore";

export class DeviceStore extends BaseStore {
  device: DeviceInterface[] | null = [];
  devices: DeviceInterface[] | null = [];

  constructor() {
    super("DeviceStore");

    makeObservable(this, {
      device: observable,
      devices: observable,
      getDevice: action,
      getDevices: action,
    });

    this.initSessionStorage(this, ["device", "devices"]);
  }

  getDevices = (departmentId?: string) => {
    let url = "/devices";

    if (departmentId) {
      url += `?departmentId=${departmentId}`;
    }

    return this.get(url, "devices", true);
  };

  getDevice = (deviceId: string) => {
    let target = "device";

    return new Promise((resolve, reject) => {
      request
        .get(`/devices/${deviceId}`)
        .then((res) => {
          const valObj = res.data;

          if (this[target] instanceof Array) {
            const index = this[target].findIndex((d: DeviceInterface) => d.id === valObj.id);

            if (index === -1) {
              this[target].push(valObj);
            } else {
              this[target].splice(index, 1, valObj);
            }
          } else {
            this[target] = [valObj];
          }

          resolve(valObj);
        })
        .catch(reject);
    });
  };

  getDeviceTelemetry = (deviceId: string) => {
    return this.get(`/devices/${deviceId}/telemetry`, "device", true);
  };

  updateDevice = (device: UpdateDeviceInterface): boolean => {
    this.put(`/devices/${device.id}`, device, "device");
    return true;
  };
}

export const deviceStore = new DeviceStore();
