import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";

interface Props {
  label?: string;
  onClick?: () => void;
  className?: string;
  type: "button" | "submit" | "reset";
  appearance: "primary" | "secondary";
  disabled?: boolean;
  children?: ReactNode | undefined;
}

const Button = (props: Props) => {
  const ctx = useFormContext();
  const isSubmitting = ctx?.formState?.isSubmitting;

  const primaryClass = "text-gray-800 rounded-full bg-blue-100 hover:bg-blue-200 border border-blue-400 py-0.5 px-4";
  const secondaryClass = "text-gray-800 rounded-full bg-gray-100 hover:bg-gray-200 border border-gray-400 py-0.5 px-4";

  return (
    <button
      disabled={props.disabled || (props.type === "submit" && isSubmitting)}
      type={props.type}
      onClick={props.onClick}
      className={`
                ${props.appearance === "primary" ? primaryClass : ""}
                ${props.appearance === "secondary" ? secondaryClass : ""}
                ${props.className || ""}
            `}
    >
      {props.label || props.children}
    </button>
  );
};

export default Button;
